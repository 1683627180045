import Axios from 'axios';
import { url } from 'config/APIShare';

const timeout = 12000;
const p = 'post';
const token_type = 'Bearer';
const access_token = localStorage.getItem('user');

export const APPROVAL_SERVICE = {
  async get(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/approval`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async getByRole(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/approval/getByRole`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },
};
