const { DISTRICT_SERVICE } = require('services/district')
const { VILLAGE_SERVICE } = require('services/village')

export const showDataMaster = {
  async district() {
    let payload = {
      size: 50,
      page: 1,
    }

    DISTRICT_SERVICE.get(payload)
      .then((res) => {
        return localStorage.setItem('districtValue', JSON.stringify(res?.data?.values))
      })
      .catch((err) => {
        console.log(err)
      })
  },

  async village() {
    let payload = {
      size: 50,
      page: 1,
    }

    VILLAGE_SERVICE.get(payload)
      .then((res) => {
        return localStorage.setItem('villageValue', JSON.stringify(res?.data?.values))
      })
      .catch((err) => {
        console.log(err)
      })
  },
}
