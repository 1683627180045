import React, { Component } from 'react'
import { Box, SimpleGrid, Heading, Input, Button } from '@chakra-ui/react'
import { DISTRICT_SERVICE } from 'services/district'

class CreateOrModifyDistrict extends Component {
  constructor(props) {
    super(props)
    this.state = {
      districtName: '',
      isOnceSubmit: false,
    }
  }

  changeHandler = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value,
    })
  }

  onSubmitOrUpdateData = () => {
    const { districtName } = this.state
    const distId = localStorage.getItem('districtId')

    this.setState({
      isOnceSubmit: true,
    })

    if (distId === 'add') {
      const payload = {
        districtName: districtName,
      }
      DISTRICT_SERVICE.create(payload)
        .then((res) => {
          console.log(res.data)
          this.setState({
            isOnceSubmit: false,
          })
          window.location.href = `/kpu/ngawi/admin/district`
        })
        .catch((err) => {
          console.log(err)
          this.setState({
            isOnceSubmit: false,
          })
        })
    } else {
      const payload = {
        districtId: distId,
        districtName: districtName,
      }
      DISTRICT_SERVICE.update(payload)
        .then((res) => {
          this.setState({
            isOnceSubmit: false,
          })
          window.location.href = `/kpu/ngawi/admin/district`
        })
        .catch((err) => {
          console.log(err)
          this.setState({
            isOnceSubmit: false,
          })
        })
    }
  }

  onGetData = () => {
    const distId = localStorage.getItem('districtId')
    if (distId !== 'add') {
      const payload = {
        districtId: distId,
      }
      DISTRICT_SERVICE.getById(payload)
        .then((res) => {
          console.log(res.data)
          if (res.data.code === 200) {
            this.setState({
              districtName: res.data.values[0].district_name,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  componentDidMount() {
    this.onGetData()
  }

  render() {
    const { districtName, isOnceSubmit } = this.state
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Heading as='h2' size='xl'>
          Tambah Data Kecamatan
        </Heading>
        <SimpleGrid
          mt='2%'
          mb='20px'
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}>
          <Heading as='h6' size='xs'>
            Nama Kecamatan
          </Heading>
          <Heading as='h6' size='xs'></Heading>
          <Input
            required
            value={districtName}
            name='districtName'
            onChange={this.changeHandler}
            variant='outline'
            placeholder='Nama Kecamatan'
          />
          <Button
            onClick={this.onSubmitOrUpdateData}
            w='50%'
            isLoading={isOnceSubmit}
            loadingText='Submitting'
            colorScheme='red'
            variant='outline'>
            Submit
          </Button>
        </SimpleGrid>
      </Box>
    )
  }
}

export default CreateOrModifyDistrict
