// Chakra Imports
import {
  Avatar,
	AvatarBadge,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
import { ThemeEditor } from './ThemeEditor';
import ChangePassword from 'views/admin/user/changePassword';
import { NOTIF_SERVICE } from 'services/notifications';
import notifImg from 'assets/img/layout/notification.png'

export default function HeaderLinks(props) {
	const { secondary } = props;
	const [countNotif, setCountNotif] = useState(0)
  const [bodyNotif, setBodyNotif] = useState([])
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');


	const onLogOut = () => {
		localStorage.removeItem('user')
		window.location.href = `/kpu/ngawi/auth/sign-in`
	}

  const onGetCountNotif = () => {
    let payloadAdmin = {
      userRole: JSON.parse(localStorage.getItem('user')).user.role,
    };

    let payloadNotAdmin = {
      userRole: JSON.parse(localStorage.getItem('user')).user.role,
      districtId: JSON.parse(localStorage.getItem('user')).user.districtId,
      villageId: JSON.parse(localStorage.getItem('user')).user.villageId,
    }

    let payload = JSON.parse(localStorage.getItem('user')).user.role === 'admin' ? payloadAdmin : payloadNotAdmin;

    NOTIF_SERVICE.countNotif(payload)
      .then((res) => {
        if (res.data.code === 200) {
          setCountNotif(res.data.values.countNotif);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onGetBodyNotif = () => {
    let payloadAdmin = {
      userRole: JSON.parse(localStorage.getItem('user')).user.role,
    };

    let payloadNotAdmin = {
      userRole: JSON.parse(localStorage.getItem('user')).user.role,
      districtId: JSON.parse(localStorage.getItem('user')).user.districtId,
      villageId: JSON.parse(localStorage.getItem('user')).user.villageId,
    }

    let payload = JSON.parse(localStorage.getItem('user')).user.role === 'admin' ? payloadAdmin : payloadNotAdmin;

    NOTIF_SERVICE.bodyNotif(payload)
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data.values, 'body');
          setBodyNotif(res.data.values);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

	useEffect(() => {
    onGetCountNotif();
    onGetBodyNotif();
  }, []);
	
	const user = localStorage.getItem('user')
	return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p='10px'
      borderRadius='30px'
      boxShadow={shadow}>
      {/* <SearchBar
        mb={secondary ? { base: '10px', md: 'unset' } : 'unset'}
        me='10px'
        borderRadius='30px'
      /> */}
      {/* <Flex
        bg={ethBg}
        display={secondary ? 'flex' : 'none'}
        borderRadius='30px'
        ms='auto'
        p='6px'
        align='center'
        me='6px'
      >
        <Flex
          align='center'
          justify='center'
          bg={ethBox}
          h='29px'
          w='29px'
          borderRadius='30px'
          me='7px'
        >
          <Icon color={ethColor} w='9px' h='14px' as={FaEthereum} />
        </Flex>
        <Text
          w='max-content'
          color={ethColor}
          fontSize='sm'
          fontWeight='700'
          me='6px'
        >
          1,924
          <Text as='span' display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex> */}
      <SidebarResponsive routes={routes} />
      <Menu>
        <MenuButton p='0px'>
          {/* <Icon
            mt='6px'
            as={MdNotificationsNone}
            color={navbarIcon}
            w='18px'
            h='18px'
            me='10px'
          ></Icon>
          <Badge>5</Badge> */}
          <Avatar
            size='sm'
            w='40px'
            h='40px'
            src={notifImg}
            p='1'
            m='2'>
            <AvatarBadge boxSize='1.25em' bg='red.500' color='white'>
              {countNotif}
            </AvatarBadge>
          </Avatar>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='20px'
          borderRadius='20px'
          bg={menuBg}
          border='none'
          mt='22px'
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}>
          <Flex jusitfy='space-between' w='100%' mb='20px'>
            <Text fontSize='md' fontWeight='600' color={textColor}>
              Notifications
            </Text>
          </Flex>
          <Flex flexDirection='column'>
            {bodyNotif.map((val, idx) => (
              <MenuItem
                key={idx}
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                px='0'
                borderRadius='8px'
                mb='10px'>
                <Text fontSize='2xl'>{val.komentar}</Text>
              </MenuItem>
            ))}
          </Flex>
        </MenuList>
      </Menu>

      {/* <ThemeEditor navbarIcon={navbarIcon} /> */}

      <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color='white'
            name={JSON.parse(user)?.user?.fullName}
            bg='#11047A'
            size='sm'
            w='40px'
            h='40px'
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='0px'
          mt='10px'
          borderRadius='20px'
          bg={menuBg}
          border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              👋&nbsp; Halo, {JSON.parse(user)?.user?.fullName}
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            {/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Profile Settings</Text>
						</MenuItem> */}
            {/* <MenuItem
              onClick={this.onPass}
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color='red.400'
              borderRadius='8px'
              px='14px'
            >
              <Text fontSize='sm'>Ganti Password</Text>
            </MenuItem> */}
            <MenuItem
              onClick={onLogOut}
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color='red.400'
              borderRadius='8px'
              px='14px'>
              <Text fontSize='sm'>Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  )
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
