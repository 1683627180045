import Axios from 'axios'
import { url } from 'config/APIShare'

const timeout = 12000
const p = 'post'
const token_type = 'Bearer'
const access_token = localStorage.getItem('user')

export const DISTRICT_SERVICE = {
  async get(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/district`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },

  async getById(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/districtById`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },

  async create(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/district/add`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },

  async update(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/district/update`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },
  
  async destroy(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/district/delete`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },
}
