import Axios from 'axios';
import { url } from 'config/APIShare';

const timeout = 12000;
const p = 'post';
const token_type = 'Bearer';
const access_token = localStorage.getItem('user');

export const PLAN_SERVICE = {
  async get(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/plan`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async add(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/plan/add`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },
};
