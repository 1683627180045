import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Icon,
  Stack,
  Input
} from '@chakra-ui/react'
import React, {useState} from 'react';
import { ToastContainer, toast } from 'react-toastify'
import { UPLOAD_SERVICE } from 'services/upload'


function ModalBasic(props) {
  const { header, body, onActionClose, action, actionName, id, modalId } = props;
  const [selectedFile, SetSelectedFile] = useState(null);
  const [sisa, setSisa]=useState('');
  const { onOpen, onClose } = useDisclosure();
  
  const onFileChange = (event) => {
    // Update the state
    SetSelectedFile(event.target.files[0])
  };

  const changeHandlerSisa = (event) => {
    const value = event.target.value
    setSisa(value)
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const onGetValidateSizeFile = (file) => {
    const MAX_FILE_SIZE = 2048 // 2MB
    const fileSizeKiloBytes = file.size / 1024
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return false
    } else {
      return true
    }
  }
  
  const onUpload = async () => {
    if (onGetValidateSizeFile(selectedFile)) {
      let file = await convertBase64(selectedFile)

      let payload = {
        cashFlowId: modalId,
        gambar: file,
        sisa: sisa
      }
      UPLOAD_SERVICE.upload(payload)
        .then((res) => {
          if (res.data.code === 200) {
            toast.success('Data Terupload', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            window.location.reload();
          }
        })
        .catch((err) => {
          toast.success('Silahkan Coba Lagi', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          console.log(err)
        })
    } else {
      toast.error('file lebih dari 2 mb', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Modal blockScrollOnMount={false} isOpen={onOpen} onClose={onActionClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <input
                type='file'
                onChange={onFileChange}
                accept='application/pdf,application/vnd.ms-excel'
              />

              <Input
                variant='outline'
                placeholder='Sisa'
                type='number'
                onChange={changeHandlerSisa}
                value={sisa}
                name='sisa'
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onActionClose}>
              Close
            </Button>
            <Button variant='ghost' onClick={onUpload}>
              {actionName}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default ModalBasic
