import React, { Component } from 'react';
import {
  Box,
  Flex,
  Button,
  Spacer,
  SimpleGrid,
  Select,
  Input,
  FormLabel,
  Heading,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { TfiSaveAlt, TfiBackLeft } from 'react-icons/tfi';
import { ToastContainer, toast } from 'react-toastify';
import Card from 'components/card/Card';
import { USER_SERVICE } from 'services/user';

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rePassword: '',
      password: '',
      showR: false,
      showP: false,
    };
  }

  onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  onChangePassword = () => {
    const { rePassword, password } = this.state;
    const user = localStorage.getItem('user');
    let payload = {
      userId: JSON.parse(user).user.userId,
      password: password,
    };
    if (rePassword === password) {
      console.log(payload);
      USER_SERVICE.changePassword(payload)
        .then((res) => {
          toast.success('Berhasil Mengubah Password', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        })
        .catch((err) => {
          toast.error('Gagal Mengubah Password', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        });
    } else {
      console.log('ditolak');
    }
  };

  onHandleShowP = () => {
    const { showP } = this.state;
    this.setState({ showP: !showP });
  };

  onHandleShowR = () => {
    const { showR } = this.state;
    this.setState({ showR: !showR });
  };

  render() {
    const { rePassword, password, showR, showP } = this.state;
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap='20px' mb='5px'>
          <Card mb='10px'>
            <Box>
              <FormLabel></FormLabel>
              <InputGroup size='md'>
                <Input
                  onChange={this.onHandleChange}
                  name='rePassword'
                  value={rePassword}
                  pr='4.5rem'
                  type={showR ? 'text' : 'password'}
                  placeholder='Masukan password'
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' onClick={this.onHandleShowR}>
                    {showR ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormLabel></FormLabel>
              <InputGroup size='md'>
                <Input
                  onChange={this.onHandleChange}
                  name='password'
                  value={password}
                  pr='4.5rem'
                  type={showP ? 'text' : 'password'}
                  placeholder='Masukan password Kembali'
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' onClick={this.onHandleShowP}>
                    {showP ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
            <Box pt={4}>
              <Button
                onClick={this.onChangePassword}
                leftIcon={<TfiSaveAlt />}
								variant='outline'
                colorScheme='teal'
                variant='solid'
                // isLoading={isOnceSubmit}
                loadingText='Loading...'
              >
                Simpan
              </Button>
            </Box>
          </Card>
        </SimpleGrid>
      </Box>
    );
  }
}

export default ChangePasswordPage;
