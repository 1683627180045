import Axios from 'axios'
import { url } from 'config/APIShare'

const timeout = 12000
const p = 'post'
const token_type = 'Bearer'
const access_token = localStorage.getItem('user')

export const USER_SERVICE = {
  async get(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/users`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async login(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/users/login`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
      timeout,
    });
  },

  async captcha(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/captcha/verify`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
      timeout,
    });
  },

  async add(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/users/register`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async getById(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/userById`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async update(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/users/update`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async destroy(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/users/delete`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async changePassword(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/users/changePassword`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },
};
