import React, { Component } from 'react'
//assets
import {
  Box,
  Flex,
  Button,
  Spacer,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  SimpleGrid,
  Select,
  Tr,
  TableContainer,
  ButtonGroup,
  Input,
  FormLabel,
	Tooltip,
} from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify'
import {
  MdModeEditOutline,
  MdDelete,
  MdCheckCircle,
  MdCancel,
  MdDownloadForOffline,
  MdFileUpload,
} from 'react-icons/md';
import { TfiSearch, TfiPencil } from 'react-icons/tfi'

import Card from 'components/card/Card'
import Pagination from 'components/pagination/pagination'
import { CASHFLOW_SERVICE } from 'services/cashflow'
import { VILLAGE_SERVICE } from 'services/village'
import { DISTRICT_SERVICE } from 'services/district'
import formatCurrency from 'utils/formatCurrency'
import { getMonthName, getYears, getFullDate } from 'utils/dateMaster'
import { GENERATE_SERVICE } from 'services/generate-pdf'
import ModalBasic from 'components/modal'
import { UPLOAD_SERVICE } from 'services/upload'

let month, year
const dateNow = new Date()
month = dateNow.getMonth() + 1
year = dateNow.getFullYear()

month = month.toString().padStart(2, '0')
let fixMonth = `${year}-${month}`

let us = localStorage.getItem('user')
let userProfile = JSON.parse(us)

class UploadDetailPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cashflowData: [],
      isLoading: true,
      currentPage: 1,
      pageSize: 10,
      totalPage: 1,
      onClose: false,
      monthNow: fixMonth,
      isOnceSubmit: false,
      villageVal: [],
      villageId: userProfile?.user?.villageId,
      districVal: [],
      districtId: userProfile?.user?.districtId,
      selectedFile: null,
      onOpenModal: false,
      modalId: ''
    }
  }

  changeHandler = (event) => {
    const name = event.target.name
    const value = event.target.value
    console.log(name, value)
    this.setState({
      [name]: value,
    })

    if (name === 'districtId') {
      this.onGetDataVillageByDis(value)
    }
  }
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] })
  }

  onUpload = async (id) => {
    let planIdUp = localStorage.getItem('uploadPlanId')
    let { selectedFile } = this.state
    if (this.onGetValidateSizeFile(selectedFile)) {
      let file = await this.convertBase64(selectedFile)

      let payload = {
        cashFlowId: id,
        gambar: file,
      }
      // Details of the uploaded file
      UPLOAD_SERVICE.upload(payload)
        .then((res) => {
          if (res.data.code === 200) {
            toast.success('Data Terupload', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      toast.error('file lebih dari 2 mb', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  onGetValidateSizeFile = (file) => {
    const MAX_FILE_SIZE = 2048 // 2MB
    const fileSizeKiloBytes = file.size / 1024
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return false
    } else {
      return true
    }
  }

  onDownloadFile = (id) => {
    let planIdUp = localStorage.getItem('uploadPlanId')
    let payload = {
      cashFlowId: id,
    }

    UPLOAD_SERVICE.getById(payload)
      .then((res) => {
        const linkSource = `${res.data.values[0].gambar}`
        const downloadLink = document.createElement('a')
        const fileName = `Planing - ${getFullDate(new Date())}`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onDelete = (id) => {
    // console.log(id, 'ddd')
    let payload = {
      cashflowId: id,
    }

    UPLOAD_SERVICE.delete(payload)
      .then((res) => {
        toast.success('Berhasil Menghapus Data', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
      .catch((err) => {
        // console.log(err)
        toast.error('Gagal Menghapus Data', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
  }

  onGetData = () => {
    const { monthNow, villageId, districtId } = this.state
    let user = localStorage.getItem('user')
    let plan = localStorage.getItem('uploadPlanId')

    this.setState({
      isOnceSubmit: true,
    })

    const payload = {
      monthNow: monthNow,
      // userId: JSON.parse(user)?.user.userId,
      districtId: districtId,
      villageId: villageId,
      planId: plan,
    }

    CASHFLOW_SERVICE.getApp(payload)
      .then((res) => {
        if (res?.data?.code === 200) {
          if (res?.data?.values.length !== 0) {
            this.setState({
              cashflowData: res?.data?.values,
              isOnceSubmit: false,
            })
          } else {
            this.setState({
              cashflowData: res?.data?.values,
              isOnceSubmit: false,
            })
            toast.error('Data Tidak ada', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          }
        }
      })
      .catch((err) => {
        this.setState({
          isOnceSubmit: false,
        })
        toast.error('Data Tidak ada', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
    console.log(payload)
  }

  onGetDataVillageByDis = (districtVal) => {
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)

    let payload = {
      districtId: districtVal,
    }
    VILLAGE_SERVICE.getVillageByDistrict(payload)
      .then((res) => {
        this.setState({
          villageVal: res?.data?.values,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onGetDataDistrict = () => {
    let payload = {
      size: 25,
      page: 1,
    }

    DISTRICT_SERVICE.get(payload)
      .then((res) => {
        this.setState({
          districVal: res?.data?.values,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onApproved = (val) => {
    let payload = {
      cashFlowId: val,
      isApproved: 1,
    }

    CASHFLOW_SERVICE.approved(payload)
      .then((res) => {
        if (res.data.code === 200) {
          this.onGetData()
        } else {
          toast.error('Service Sedang Sibuk', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      })
      .catch((err) => {
        toast.error('Service Sedang Sibuk', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
  }

  onUploadItem = (id) => {}

  onGeneratePDF = () => {
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)
    const { cashflowData } = this.state

    let payload = {
      bodyPayload: {
        values: cashflowData,
        tahun: getYears(new Date()),
        bulan: getMonthName(new Date()),
        penyelenggara: userProfile?.user?.role,
        kecamatan: userProfile?.user?.districtName,
      },
      footer: {
        wilayah: 'NGAWI',
        tanggal: getFullDate(new Date()),
        atasnama: `Ketua ${userProfile?.user?.role} ${userProfile?.user?.districtName}`,
      },
      jenis: 'cashflow',
    }

    GENERATE_SERVICE.generatePDF(payload)
      .then((res) => {
        if (res.data.code === 200) {
          const linkSource = `data:application/pdf;base64,${res.data.values}`
          const downloadLink = document.createElement('a')
          const fileName = `Rencana Anggaran - ${getFullDate(new Date())}`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onGoToCreateOrModify = (id) => {
    let cashId = id === undefined ? 'add' : id
    localStorage.setItem('cahflowId', cashId)
    window.location.href = `/kpu/ngawi/admin/createOrModifycashflow`
  }

  onDeleteData = (id) => {
    const payload = {
      cashFlowId: id,
    }
    CASHFLOW_SERVICE.destroy(payload)
      .then((res) => {
        this.onGetData()
        console.log(res)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  paginateFront = () => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1, isLoading: true })
    this.onGetDataVillage(currentPage + 1)
  }

  paginateBack = (data) => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1, isLoading: true })
    this.onGetDataVillage(currentPage - 1)
  }

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber, isLoading: true })
    this.onGetDataVillage(pageNumber)
  }

  componentDidMount() {
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)

    this.onGetData()
    // if( userProfile?.user?.role === 'admin'){
    this.onGetDataDistrict()
    this.onGetDataVillageByDis(Number(userProfile?.user.districtId))
    // }
  }

  onEditSisa = (e, id) => {
    const { name, value } = e.target
    console.log(name, value, id)
  }

  onOpenModal = (id) => {
    this.setState({ onOpenModal: true, modalId: id })
  }

  onCloseModal = (id) => {
    this.setState({ onOpenModal: false })
  }

  render() {
    const {
      cashflowData,
      currentPage,
      totalPage,
      pageSize,
      onClose,
      monthNow,
      isOnceSubmit,
      villageVal,
      villageId,
      districVal,
      districtId,
      modalIsOpen,
      comment,
      onOpenModal,
      modalId
    } = this.state
    let headerElement = [
      'Kode Rekening',
      'Uraian',
      'Jumlah',
      'File',
      'Sisa',
      'Komentar',
      'action',
    ]
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)
    console.log(villageVal)

    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        {onOpenModal ? <ModalBasic body='test' actionName="submit" onActionClose={this.onCloseModal} modalId={modalId} /> : <></>}
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
        <Card
          direction='column'
          w='100%'
          px='0px'
          overflowX={{ sm: 'scroll', lg: 'hidden' }}>
          <TableContainer>
            <Table colorScheme='tomato' variant='striped'>
              <Thead>
                <Tr>
                  {headerElement.map((key, index) => {
                    return (
                      <Th key={index} fontFamily='revert'>
                        <Flex
                          justify='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'>
                          {key.toUpperCase()}
                        </Flex>
                      </Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {cashflowData.map(
                  ({
                    kode_reg,
                    cashflow_id,
                    description,
                    amount,
                    is_approved,
                    gambar,
                    upload_id,
                    komentar,
                    sisa,
                  }) => {
                    console.log(is_approved, 'nask')
                    return (
                      <Tr key={kode_reg} fontFamily='revert'>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'>
                          <Text>{kode_reg}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'>
                          <Text>{description}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'>
                          <Text>{formatCurrency(amount)}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'>
                          {gambar === '' || gambar === null ? (
                             <Text color='red'>{'-'}</Text>
                          ) : (
                            <Text color='green'>{'terupload'}</Text>
                          )}
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'>
                          <Text>{sisa}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'>
                          <Text color='red'>{komentar}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'>
                          <Flex align='centre'>
                            {gambar === '' || gambar === null ? (
                              <div>
                                {/* <Tooltip
                                  label='Upload File'
                                  hasArrow
                                  arrowSize={15}>
                                  <Button
                                    size='sm'
                                    m='2px'
                                    colorScheme='whatsapp'
                                    onClick={() => this.onUpload(cashflow_id)}>
                                    <Icon w='16px' h='16px' as={MdFileUpload} />
                                  </Button>
                                </Tooltip> */}

                                <Tooltip
                                  label='Upload File'
                                  hasArrow
                                  arrowSize={15}>
                                  <Button
                                    size='sm'
                                    m='2px'
                                    colorScheme='whatsapp'
                                    onClick={() =>
                                      this.onOpenModal(cashflow_id)
                                    }>
                                    <Icon w='16px' h='16px' as={MdFileUpload} />
                                  </Button>
                                </Tooltip>
                              </div>
                            ) : (
                              <div>
                                <Tooltip
                                  label='Download File'
                                  hasArrow
                                  arrowSize={15}>
                                  <Button
                                    size='sm'
                                    m='2px'
                                    colorScheme='blue'
                                    onClick={() =>
                                      this.onDownloadFile(cashflow_id)
                                    }>
                                    <Icon
                                      w='16px'
                                      h='16px'
                                      as={MdDownloadForOffline}
                                    />
                                  </Button>
                                </Tooltip>

                                <Tooltip
                                  label='Hapus File upload'
                                  hasArrow
                                  arrowSize={15}>
                                  <Button
                                    size='sm'
                                    m='2px'
                                    colorScheme='red'
                                    onClick={() => this.onDelete(cashflow_id)}>
                                    <Icon w='16px' h='16px' as={MdDelete} />
                                  </Button>
                                </Tooltip>
                              </div>
                            )}
                          </Flex>
                        </Td>
                      </Tr>
                    )
                  },
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          currentPage={currentPage}
          totalRecords={totalPage}
          pageLimit={parseInt(pageSize)}
          pageNeighbours={1}
          paginate={this.paginate}
          paginateBack={this.paginateBack}
          paginateFront={this.paginateFront}
        />
      </Box>
    )
  }
}

export default UploadDetailPage
