import React, { useState, useEffect } from 'react';

// Chakra imports
import {
  Flex,
  Box,
  Icon,
  Text,
  Spacer,
  Image,
  Heading,
	useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';

// Assets
import bgMastercard from 'assets/img/dashboards/card.jpeg';
import logoKpu from 'assets/img/dashboards/kpu.png';
import { RiMastercardFill } from 'react-icons/ri';
import { REPORT_SERVICE } from 'services/report';
import formatCurrency from 'utils/formatCurrency';

let month, year;
const dateNow = new Date();
month = dateNow.getMonth() + 1;
year = dateNow.getFullYear();

month = month.toString().padStart(2, '0');
let fixMonth = `${year}-${month}`;
export default function UserReports(props) {
  // const [isOpen, setIsOpen] = useState(false)
  //   // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [sumPPK, setSumPPK] = useState(0);
  const [sumPPS, setSumPPS] = useState(0);
  const [sumAll, setSumAll] = useState(0);
  const us = localStorage.getItem('user');
	const Nama = JSON.parse(us).user.fullName;
	
  // Chakra Color Mode
  return (
    <Card
      backgroundImage={bgMastercard}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      alignSelf='center'
      w={{ base: '100%', md: '30%', xl: '100%' }}
      bgPosition='10%'
      mx='auto'
      p='20px'
    >
      <Flex direction='column' color='white' h='100%' w='100%'>
        <Flex justify='space-between' align='center' mb='37px'>
          <Text fontSize='2xl' fontWeight='bold'>
            Komisi Pemilihan Umum
          </Text>
          <p></p>
          <Text fontSize='2xl' fontWeight='bold'>
            {Nama}
          </Text>
          <Image src={logoKpu} w='48px' h='auto' />
        </Flex>
        <Spacer />
        <Flex direction='column'>
          <Box>
            <Heading
              fontSize={{ sm: '3xl', lg: '3lg', xl: '3xl' }}
              fontWeight='bold'
            >
              <Text fontSize='sm' fontWeight='bold'>
                Saldo
              </Text>

              {formatCurrency(props.anggaranVal ?? 0)}
            </Heading>
          </Box>
          <Flex mt='14px'>
            <Flex direction='column' me='34px'>
              <Text fontSize='xs'>Bulan</Text>
              <Text fontSize='sm' fontWeight='500'>
                {month}
              </Text>
            </Flex>
            <Flex direction='column'>
              <Text fontSize='xs'>Tahun</Text>
              <Text fontSize='sm' fontWeight='500'>
                {year}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
