import React, { Component } from 'react'
import { Box, SimpleGrid, Heading, Input, Button } from '@chakra-ui/react'
import { VILLAGE_SERVICE } from 'services/village'

class CreateOrModifyVillage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      villageName: '',
      isOnceSubmit: false,
    }
  }

  changeHandler = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value,
    })
  }

  onSubmitOrUpdateData = () => {
    const { villageName } = this.state
    const villId = localStorage.getItem('villageId')

    this.setState({
      isOnceSubmit: true,
    })

    if (villId === 'add') {
      const payload = {
        villageName: villageName,
      }
      VILLAGE_SERVICE.create(payload)
        .then((res) => {
          console.log(res.data)
          this.setState({
            isOnceSubmit: false,
          })
          window.location.href = `/kpu/ngawi/admin/village`
        })
        .catch((err) => {
          console.log(err)
          this.setState({
            isOnceSubmit: false,
          })
        })
    } else {
      const payload = {
        villageId: villId,
        villageName: villageName,
      }
      VILLAGE_SERVICE.update(payload)
        .then((res) => {
          this.setState({
            isOnceSubmit: false,
          })
          window.location.href = `/kpu/ngawi/admin/village`
        })
        .catch((err) => {
          console.log(err)
          this.setState({
            isOnceSubmit: false,
          })
        })
    }
  }

  onGetData = () => {
    const villId = localStorage.getItem('villageId')
    if (villId !== 'add') {
      const payload = {
        villageId: villId,
      }
      VILLAGE_SERVICE.getById(payload)
        .then((res) => {
          console.log(res.data)
          if (res.data.code === 200) {
            this.setState({
              villageName: res.data.values[0].village_name,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  componentDidMount() {
    this.onGetData()
  }

  render() {
    const { villageName, isOnceSubmit } = this.state
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Heading as='h2' size='xl'>
          Tambah Data Desa
        </Heading>
        <SimpleGrid
          mt='2%'
          mb='20px'
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}>
          <Heading as='h6' size='xs'>
            Nama Desa
          </Heading>
          <Heading as='h6' size='xs'></Heading>
          <Input
            required
            value={villageName}
            name='villageName'
            onChange={this.changeHandler}
            variant='outline'
            placeholder='Nama Desa'
          />
          <Button
            onClick={this.onSubmitOrUpdateData}
            w='50%'
            isLoading={isOnceSubmit}
            loadingText='Submitting'
            colorScheme='red'
            variant='outline'>
            Submit
          </Button>
        </SimpleGrid>
      </Box>
    )
  }
}

export default CreateOrModifyVillage
