import React, { Component } from 'react';
//assets
import {
  Box,
  Flex,
  Button,
  Spacer,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  SimpleGrid,
  Select,
  Tr,
  TableContainer,
  ButtonGroup,
  FormLabel,
	Tooltip,
} from '@chakra-ui/react';
import { MdModeEditOutline, MdDelete, MdCheckCircle, MdCancel,  } from 'react-icons/md';
import { TfiSearch, TfiPencil, TfiPencilAlt } from 'react-icons/tfi';
// import { ToastContainer, toast } from 'react-toastify';
import Card from 'components/card/Card';
import Pagination from 'components/pagination/pagination';
import { USER_SERVICE } from 'services/user';

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      isLoading: true,
      currentPage: 1,
      pageSize: 10,
      totalPage: 1,
      onClose: false,
      isOnceSubmit:false,
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };


  onGetDataUser = (currentPage) => {
    console.log('for service');
    const { pageSize } = this.state;
    const pagination = {
      size: pageSize,
      page: currentPage,
    };

    USER_SERVICE.get(pagination)
      .then((res) => {
        if (res?.data?.code === 200) {
          this.setState({
            userData: res?.data?.values,
            totalPage: res?.data?.paginate?.totalData,
          });
        }
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  };

  onGoToCreateOrModify = (id) => {
    let useId = id === undefined ? 'add' : id;
    localStorage.setItem('userId', useId);
    window.location.href = `/kpu/ngawi/admin/createOrModifyUser`;
  };

  onDeleteData = (id) => {
    const payload = {
      userId: id,
    };
    USER_SERVICE.destroy(payload)
      .then((res) => {
        this.onGetDataUser(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  };

  paginateFront = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1, isLoading: true });
    this.onGetDataUser(currentPage + 1);
  };

  paginateBack = (data) => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, isLoading: true });
    this.onGetDataUser(currentPage - 1);
  };

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber, isLoading: true });
    this.onGetDataUser(pageNumber);
  };

  componentDidMount() {
    const { currentPage } = this.state;
    this.onGetDataUser(currentPage);
  }

  render() {
    const {
      userData,
      currentPage,
      totalPage,
      pageSize,
      isOnceSubmit,
    } = this.state;
		console.log(userData)
    let headerElement = [
      'Kecamatan',
      'Desa',
      'Nama',
      'username',
      'Email',
      'Action',
    ];
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap='20px' mb='5px'>
          <Box>
            <Card>
              <FormLabel>Filter Data</FormLabel>
              <Select placeholder='Kecamatan' />
              <FormLabel></FormLabel>
              <Select placeholder='Desa' />

              <FormLabel mb='15px'></FormLabel>
              <Button
                onClick={this.onGetData}
                leftIcon={<TfiSearch />}
                isLoading={isOnceSubmit}
                loadingText='Loading...'
                colorScheme='telegram'
                variant='outline'
              >
                Show
              </Button>
            </Card>
          </Box>
          <Spacer />
          <Box></Box>
        </SimpleGrid>

        <Flex
          minWidth='max-content'
          alignItems='center'
          gap='2'
          pb='10px'
          mb='15px'
        >
          <Spacer />
          <ButtonGroup gap='2'>
            <Button
              colorScheme='teal'
              variant='solid'
              leftIcon={<TfiPencilAlt />}
              p={5}
              onClick={() => this.onGoToCreateOrModify()}
            >
              Tambah Data
            </Button>
          </ButtonGroup>
        </Flex>

        <Card
          direction='column'
          w='100%'
          px='0px'
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <TableContainer>
            <Table colorScheme='tomato' variant='striped'>
              <Thead>
                <Tr>
                  {headerElement.map((key, index) => {
                    return (
                      <Th key={index} fontFamily='revert'>
                        <Flex
                          justify='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                        >
                          {key.toUpperCase()}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {userData.map(
                  ({
                    user_id,
                    district_name,
                    village_name,
                    name,
                    username,
                    email,
                  }) => {
                    console.log(district_name);
                    return (
                      <Tr key={district_name} fontFamily='revert'>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>
                            {district_name === '' ? '-' : district_name}
                          </Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>
                            {village_name === '' ? '-' : village_name}
                          </Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{name}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{username}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{email}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Flex align='centre'>
                            <Tooltip label='Ubah data' hasArrow arrowSize={15}>
                              <Button
                                variant='outline'
                                size='sm'
                                m='2px'
                                colorScheme='whatsapp'
                                onClick={() =>
                                  this.onGoToCreateOrModify(user_id)
                                }
                              >
                                <Icon
                                  w='16px'
                                  h='16px'
                                  as={MdModeEditOutline}
                                />
                              </Button>
                            </Tooltip>
                            <Tooltip label='Hapus data' hasArrow arrowSize={15}>
                              <Button
                                variant='outline'
                                size='sm'
                                m='2px'
                                colorScheme='red'
                                onClick={() => this.onDeleteData(user_id)}
                              >
                                <Icon w='16px' h='16px' as={MdDelete} />
                              </Button>
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          currentPage={currentPage}
          totalRecords={totalPage}
          pageLimit={parseInt(pageSize)}
          pageNeighbours={1}
          paginate={this.paginate}
          paginateBack={this.paginateBack}
          paginateFront={this.paginateFront}
        />
      </Box>
    );
  }
}

export default UserPage;
