/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


import React, {useState} from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { USER_SERVICE } from 'services/user';
import { ToastContainer, toast } from 'react-toastify';
import CaptchaBase from "components/captcha";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
  const [isOnceSubmit, setIsOnceSubmit] = useState(false);

  const [flagLogin, SetFlagLogin] = useState(false);

  const onLogin = async () => {
    setIsOnceSubmit(true)
    const request = {
      username: username,
      password: password,
    }
    const capt = {
      token: localStorage.getItem('captcha')
    }
    await USER_SERVICE.login(request)
      .then((response) => {
        if (response.data.code === 200) {
          SetFlagLogin(true)
          setIsOnceSubmit(false)
          localStorage.setItem('user', JSON.stringify(response.data.values))
          // window.location.href = `/kpu/ngawi/admin/default`
        } else {
          setIsOnceSubmit(false)
          toast.error('Pastikan Username dan Password Anda', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      })
      .catch((error) => {
        setIsOnceSubmit(false)
        toast.error('Pastikan Username dan Password Anda', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })

      USER_SERVICE.captcha(capt)
      .then((response) => {
        if (response.data.code === 200) {
          window.location.href = `/kpu/ngawi/admin/default`
        } else {
          toast.error('Pastikan Melakukan verify', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      })
      .catch((error) => {
        toast.error('Pastikan Melakukan verify', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
  }

	const changeHandlerPassword = (event) => {
    const value = event.target.value;
		setPassword(value);
  };
  
	const changeHandlerUsername = (event) => {
    const value = event.target.value;
		setUsername(value);
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Masukan username dan password untuk Login!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Username<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: '0px', md: '0px' }}
            type='text'
            placeholder='mail@simmmple.com'
            mb='24px'
            name='username'
            fontWeight='500'
            value={username}
            onChange={changeHandlerUsername}
            size='lg'
          />
          <FormLabel
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            display='flex'>
            Password<Text color={brandStars}>*</Text>
          </FormLabel>
          <InputGroup size='md'>
            <Input
              isRequired={true}
              fontSize='sm'
              placeholder='Min. 8 characters'
              mb='24px'
              size='lg'
              name='password'
              value={password}
              onChange={changeHandlerPassword}
              type={show ? 'text' : 'password'}
              variant='auth'
            />
            <InputRightElement display='flex' alignItems='center' mt='4px'>
              <Icon
                color={textColorSecondary}
                _hover={{ cursor: 'pointer' }}
                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={handleClick}
              />
            </InputRightElement>
          </InputGroup>
          <Flex justifyContent='space-between' align='center' mb='24px'>
            {/* <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink> */}
          </Flex>
          <CaptchaBase/>
          <Button
            w='100%'
            h='50'
            mb='24px'
            fontWeight='500'
            fontSize='sm'
            onClick={onLogin}
            isLoading={isOnceSubmit}
            loadingText='Please Wait ...'
            colorScheme='red'
            variant='outline'>
            Sign In
          </Button>
        </Flex>
      </Flex>
    </DefaultAuth>
  )
}

// isLoading
//     loadingText='Submitting'
//     variant='outline'
export default SignIn;
