import React, { Component } from 'react';
//assets
import {
  Box,
  Flex,
  Button,
  Spacer,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  SimpleGrid,
  Select,
  Tr,
  TableContainer,
  ButtonGroup,
  Input,
  FormLabel,
	Tooltip,
} from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
import {
  MdModeEditOutline,
  MdDelete,
  MdCheckCircle,
  MdCancel,
} from 'react-icons/md';
import { TfiSearch, TfiPencil, TfiPencilAlt, TfiDownload, TfiList } from 'react-icons/tfi';

import Card from 'components/card/Card';
import Pagination from 'components/pagination/pagination';
import { PLAN_SERVICE } from 'services/plan';
import { VILLAGE_SERVICE } from 'services/village';
import { DISTRICT_SERVICE } from 'services/district';
import { getMonthName, getYears, getFullDate } from 'utils/dateMaster';
import { GENERATE_SERVICE } from 'services/generate-pdf';
import { UPLOAD_SERVICE } from 'services/upload';

let month, year;
const dateNow = new Date();
month = dateNow.getMonth() + 1;
year = dateNow.getFullYear();

month = month.toString().padStart(2, '0');
let fixMonth = `${year}-${month}`;

let us = localStorage.getItem('user');
let userProfile = JSON.parse(us);

class PlanPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cashflowData: [],
      isLoading: true,
      currentPage: 1,
      pageSize: 2,
      totalPage: 1,
      onClose: false,
      monthNow: fixMonth,
      isOnceSubmit: false,
      villageVal: [],
      villageId: userProfile?.user?.villageId,
      districVal: [],
      districtId: userProfile?.user?.districtId,
      selectedFile: null,
    }
  }

  changeHandler = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value,
    })

    if (name === 'districtId') {
      this.onGetDataVillageByDis(value)
    }
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] })
  }

  onUpload = async () => {
    let { selectedFile } = this.state
    if (this.onGetValidateSizeFile(selectedFile)) {
      let file = await this.convertBase64(selectedFile)
      console.log(file, 'file')
    }
    // Details of the uploaded file
    
    // UPLOAD_SERVICE.upload(formData).
    // then(res => {
    //   console.log(res, 'response');
    // }).catch(err => {
    //   console.log(err)
    // })
  }

  onGoToCreateOrUpdatePlan = (id) => {
    let cashId = id === undefined ? 'add' : id
    localStorage.setItem('planId', cashId)
    window.location.href = `/kpu/ngawi/admin/createOrModifyPlan`
  }

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  onGetValidateSizeFile = (file) => {
    const MAX_FILE_SIZE = 500 // 5MB
    const fileSizeKiloBytes = file.size / 1024
    if(fileSizeKiloBytes > MAX_FILE_SIZE){
      console.log("File size is greater than maximum limit");
      return false
    } else {
      return true
    }
  }

  onGetData = (currentPage) => {
    const { villageId, districtId, pageSize } = this.state
    let user = localStorage.getItem('user')

    this.setState({
      isOnceSubmit: true,
    })

    const payload = {
      userId: JSON.parse(user)?.user.userId,
      districtId: districtId,
      villageId: villageId,
      size: pageSize,
      page: currentPage,
    }

    PLAN_SERVICE.get(payload)
      .then((res) => {
        if (res?.data?.code === 200) {
          if (res?.data?.values.length !== 0) {
            this.setState({
              cashflowData: res?.data?.values,
              isOnceSubmit: false,
              totalPage: res?.data?.paginate?.totalData,
            })
          } else {
            this.setState({
              cashflowData: res?.data?.values,
              totalPage: res?.data?.paginate?.totalData,
              isOnceSubmit: false,
            })
            toast.error('Data Tidak ada', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          }
        }
      })
      .catch((err) => {
        this.setState({
          isOnceSubmit: false,
        })
        toast.error('Data Tidak ada', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
    console.log(payload)
  }

  onGetDataVillageByDis = (districtVal) => {
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)

    let payload = {
      districtId: districtVal,
    }
    VILLAGE_SERVICE.getVillageByDistrict(payload)
      .then((res) => {
        this.setState({
          villageVal: res?.data?.values,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onGetDataDistrict = () => {
    let payload = {
      size: 25,
      page: 1,
    }

    DISTRICT_SERVICE.get(payload)
      .then((res) => {
        this.setState({
          districVal: res?.data?.values,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onApproved = (val) => {
    let payload = {
      cashFlowId: val,
      isApproved: 1,
    }

    PLAN_SERVICE.approved(payload)
      .then((res) => {
        if (res.data.code === 200) {
          this.onGetData()
        } else {
          toast.error('Service Sedang Sibuk', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      })
      .catch((err) => {
        toast.error('Service Sedang Sibuk', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
  }

  onGeneratePDF = () => {
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)
    const { cashflowData } = this.state

    let payload = {
      bodyPayload: {
        values: cashflowData,
        tahun: getYears(new Date()),
        bulan: getMonthName(new Date()),
        penyelenggara: userProfile?.user?.role,
        kecamatan: userProfile?.user?.districtName,
      },
      footer: {
        wilayah: 'NGAWI',
        tanggal: getFullDate(new Date()),
        atasnama: `Ketua ${userProfile?.user?.role} ${userProfile?.user?.districtName}`,
      },
      jenis: 'plan',
    }

    GENERATE_SERVICE.generatePDF(payload)
      .then((res) => {
        if (res.data.code === 200) {
          const linkSource = `data:application/pdf;base64,${res.data.values}`
          const downloadLink = document.createElement('a')
          const fileName = `Planing - ${getFullDate(new Date())}`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // onGetDataCashflow = (currentPage) => {
  //   console.log('for service');
  //   const { pageSize } = this.state;
  //   const pagination = {
  //     size: pageSize,
  //     page: currentPage,
  //   };

  //   CASHFLOW_SERVICE.get(pagination)
  //     .then((res) => {
  //       if (res?.data?.code === 200) {
  //         this.setState({
  //           chasflowData: res?.data?.values,
  //           totalPage: res?.data?.paginate?.totalData,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, 'err');
  //     });
  // };

  onGoDetail = (id) => {
    let planId = id === undefined ? 'add' : id
    localStorage.setItem('planId', planId)
    window.location.href = `/kpu/ngawi/admin/cashflow`
  }

  // onDeleteData = (id) => {
  //   const payload = {
  //     cashFlowId: id,
  //   };
  //   CASHFLOW_SERVICE.destroy(payload)
  //     .then((res) => {
  //       this.onGetData();
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err, 'err');
  //     });
  // };

  paginateFront = () => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1, isLoading: true })
    this.onGetData(currentPage + 1)
  }

  paginateBack = (data) => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1, isLoading: true })
    this.onGetData(currentPage - 1)
  }

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber, isLoading: true })
    this.onGetData(pageNumber)
  }

  componentDidMount() {
    const {currentPage} = this.state;
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)

    this.onGetData(currentPage)
    // if( userProfile?.user?.role === 'admin'){
    this.onGetDataDistrict()
    this.onGetDataVillageByDis(Number(userProfile?.user.districtId))
    // }
  }

  render() {
    const {
      cashflowData,
      currentPage,
      totalPage,
      pageSize,
      onClose,
      monthNow,
      isOnceSubmit,
      villageVal,
      villageId,
      districVal,
      districtId,
    } = this.state
    let headerElement = ['Uraian', 'Status', 'action']
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)
    console.log(currentPage, 'ksnkds')

    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card mb='10px'>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
          <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap='20px' mb='5px'>
            <Box>
              {userProfile?.user?.role === 'admin' ? (
                <FormLabel>Filter Data</FormLabel>
              ) : (
                <FormLabel>Data</FormLabel>
              )}
              {userProfile?.user?.role === 'admin' ? (
                <Select
                  placeholder='Kecamatan'
                  name='districtId'
                  value={districtId}
                  onChange={this.changeHandler}>
                  {districVal.map((val) => (
                    <option value={val.district_id}>{val.district_name}</option>
                  ))}
                </Select>
              ) : (
                <Box pl={4} pt={2} pb={2}>
                  {userProfile?.user?.districtName}
                </Box>
              )}

              <FormLabel></FormLabel>
              {userProfile?.user?.role === 'admin' ||
              userProfile?.user?.role === 'ppk' ? (
                <Select
                  pb={2}
                  placeholder='Desa'
                  name='villageId'
                  value={villageId}
                  onChange={this.changeHandler}>
                  {villageVal.map((val) => (
                    <option value={val.village_id}>{val.village_name}</option>
                  ))}
                </Select>
              ) : (
                <Box pl={4} pb={2}>
                  {userProfile?.user?.villageName}
                </Box>
              )}

              <FormLabel></FormLabel>
              <Button
                onClick={this.onGetData}
                leftIcon={<TfiSearch />}
                isLoading={isOnceSubmit}
                loadingText='Loading...'
                colorScheme='telegram'
                variant='solid'>
                Show
              </Button>
            </Box>
            <Box p='2'></Box>
            <Box>
              <FormLabel>Filter Bulan</FormLabel>
              <Input
                placeholder='Select Date and Time'
                size='md'
                type='month'
                value={monthNow}
                name='monthNow'
                onChange={this.changeHandler}
              />
            </Box>
          </SimpleGrid>
        </Card>

        <Flex
          minWidth='max-content'
          alignItems='center'
          gap='2'
          pb='10px'
          mb='15px'>
          <Spacer />
          <ButtonGroup gap='2'>
            
            <Button
              leftIcon={<TfiPencilAlt />}
              colorScheme='teal'
              fontWeight='500'
              onClick={()=>this.onGoToCreateOrUpdatePlan('add')}>
              Tambah Data
            </Button>
            <Box>
              <ButtonGroup gap='2'>
                <Button
                  colorScheme='red'
                  variant='solid'
                  leftIcon={<TfiDownload />}
                  p={5}
                  onClick={() => this.onGeneratePDF()}>
                  PDF
                </Button>
              </ButtonGroup>
            </Box>
          </ButtonGroup>
        </Flex>

        <Card
          direction='column'
          w='100%'
          px='0px'
          overflowX={{ sm: 'scroll', lg: 'hidden' }}>
          <TableContainer>
            <Table colorScheme='tomato' variant='striped'>
              <Thead>
                <Tr>
                  {headerElement.map((key, index) => {
                    return (
                      <Th key={index} fontFamily='revert'>
                        <Flex
                          justify='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'>
                          {key.toUpperCase()}
                        </Flex>
                      </Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {cashflowData.map(({ plan_id, plan_name, is_approved }) => {
									console.log(plan_id, 'pp');
                  return (
                    <Tr key={plan_id} fontFamily='revert'>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'
                      >
                        <Text>{plan_name}</Text>
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'
                      >
                        <Text>
                          {' '}
                          <Icon
                            w='24px'
                            h='24px'
                            me='5px'
                            color={
                              is_approved === '1' ? 'green.500' : 'red.500'
                            }
                            as={is_approved === '1' ? MdCheckCircle : MdCancel}
                          />
                          {is_approved === '1' ? 'Approve' : 'Pending'}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'
                      >
                        <Flex align='centre'>
                          <Tooltip label='Detail data' hasArrow arrowSize={15}>
													<Button
                            variant='outline'
                            leftIcon={<TfiList />}
                            size='sm'
                            m='2px'
                            colorScheme='whatsapp'
                            onClick={() => this.onGoDetail(plan_id)}
                          >
                            {' '}
                            Detail
                          </Button>
													</Tooltip>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          currentPage={currentPage}
          totalRecords={totalPage}
          pageLimit={parseInt(pageSize)}
          pageNeighbours={1}
          paginate={this.paginate}
          paginateBack={this.paginateBack}
          paginateFront={this.paginateFront}
        />
      </Box>
    )
  }
}

export default PlanPage;
