import React, { Component } from 'react';
//assets
import {
  Box,
  Flex,
  Button,
  Spacer,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  SimpleGrid,
  Select,
  Tr,
  TableContainer,
  ButtonGroup,
  FormLabel,
  Input,
	Tooltip,
} from '@chakra-ui/react';
import {
  MdModeEditOutline,
  MdDelete,
  MdCheckCircle,
  MdCancel,
} from 'react-icons/md';
import { TfiSearch, TfiPencil, TfiDownload, TfiList } from 'react-icons/tfi';
// import { ToastContainer, toast } from 'react-toastify';
import Card from 'components/card/Card';
import Pagination from 'components/pagination/pagination';
import { USER_SERVICE } from 'services/user';
import { APPROVAL_SERVICE } from 'services/approval';
import { getMonthName, getYears, getFullDate } from 'utils/dateMaster';
import { GENERATE_SERVICE } from 'services/generate-pdf';

let month, year;
const dateNow = new Date();
month = dateNow.getMonth() + 1;
year = dateNow.getFullYear();

month = month.toString().padStart(2, '0');
let fixMonth = `${year}-${month}`;

class LaporanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalLpj: [],
      isLoading: true,
      currentPage: 1,
      pageSize: 2,
      totalPage: 1,
      monthNow: fixMonth,
      onClose: false,
      isOnceSubmit: false,
      role: '',
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });

    // if (name === 'role') {
    //   this.onGetData(value);
    // }
  };

  onGoDetail = (userId, villageId, districtId) => {
    localStorage.setItem('userId', userId);
    localStorage.setItem('villageId', villageId);
    localStorage.setItem('districtId', districtId);

    window.location.href = `/kpu/ngawi/admin/detailLpj`;

    // let planId = id === undefined ? 'add' : id
    // localStorage.setItem('planId', planId)
    // window.location.href = `/kpu/ngawi/admin/plan`
  };

  onGetData = (currentPage) => {
    const { monthNow, role, pageSize } = this.state
    const payload = {
      role: role,
      month: monthNow,
      size: pageSize,
      page: currentPage,
    }

    APPROVAL_SERVICE.getByRole(payload)
      .then((res) => {
        this.setState({
          approvalLpj: res.data.values,
          totalPage: res?.data?.paginate?.totalData,
        })
      })
      .catch((err) => {
        console.log(err, 'jdfdj');
      });
  };

  onGoToCreateOrModify = (id) => {
    let useId = id === undefined ? 'add' : id;
    localStorage.setItem('userId', useId);
    window.location.href = `/kpu/ngawi/admin/createOrModifyUser`;
  };

  onDeleteData = (id) => {
    const payload = {
      userId: id,
    };
    USER_SERVICE.destroy(payload)
      .then((res) => {
        this.onGetDataUser(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  };

  paginateFront = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1, isLoading: true });
    this.onGetDataUser(currentPage + 1);
  };

  paginateBack = (data) => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, isLoading: true });
    this.onGetDataUser(currentPage - 1);
  };

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber, isLoading: true });
    this.onGetDataUser(pageNumber);
  };

  onGeneratePDF = () => {
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)
    const { approvalLpj } = this.state

    let payload = {
      bodyPayload: {
        values: approvalLpj,
        tahun: getYears(new Date()),
        bulan: getMonthName(new Date()),
        penyelenggara: 'KPU Kabupaten Ngawi',
        kecamatan: userProfile?.user?.districtName,
      },
      footer: {
        wilayah: 'NGAWI',
        tanggal: getFullDate(new Date()),
        // atasnama: `Ketua ${userProfile?.user?.role} ${userProfile?.user?.districtName}`,
        atasnama: 'KPU Kabupaten Ngawi'
      },
      jenis: 'approval',
    }

    GENERATE_SERVICE.generatePDF(payload)
      .then((res) => {
        if (res.data.code === 200) {
          const linkSource = `data:application/pdf;base64,${res.data.values}`
          const downloadLink = document.createElement('a')
          const fileName = `Rencana Anggaran - ${getFullDate(new Date())}`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  componentDidMount() {
    // this.onGetData('');
  }

  render() {
    const {
      userData,
      currentPage,
      totalPage,
      monthNow,
      pageSize,
      role,
      approvalLpj,
    } = this.state;
    console.log(userData);
    let headerElement = ['Kecamatan', 'Desa', 'Nama', 'Status', 'Action'];
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap='20px' mb='15px'>
          <Card mb='10px'>
            <Box>
              <FormLabel></FormLabel>
              <Select
                placeholder='Level'
                name='role'
                value={role}
                onChange={this.changeHandler}
              >
                <option values='ppk'>PPK</option>
                <option values='pps'>PPS</option>
              </Select>
              <FormLabel></FormLabel>
              <Input
                placeholder='Select Date and Time'
                size='md'
                type='month'
                value={monthNow}
                name='monthNow'
                onChange={this.changeHandler}
              />
            </Box>
            <Button
                colorScheme='red'
                variant='outline'
                leftIcon={<TfiDownload />}
                p={5}
                size='sm'
                onClick={() => this.onGetData(currentPage)}
              >
                search
              </Button>
          </Card>
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap='20px' mb='15px'>
          <Spacer />
          <Spacer />
          <Box>
            <ButtonGroup gap='2'>
              <Button
                colorScheme='red'
                variant='outline'
                leftIcon={<TfiDownload />}
                p={5}
                size='sm'
                onClick={() => this.onGeneratePDF()}
              >
                PDF
              </Button>
            </ButtonGroup>
          </Box>
        </SimpleGrid>

        <Card
          direction='column'
          w='100%'
          px='0px'
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <TableContainer>
            <Table colorScheme='tomato' variant='striped'>
              <Thead>
                <Tr>
                  {headerElement.map((key, index) => {
                    return (
                      <Th key={index} fontFamily='revert'>
                        <Flex
                          justify='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                        >
                          {key.toUpperCase()}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {approvalLpj.map(
                  ({
                    user_id,
                    district_name,
                    village_name,
                    village_id,
                    district_id,
                    name,
                    is_approved,
                    plan_id,
                    role,
                  }) => {
                    console.log(user_id, village_id, district_id, 'aplpj');
                    return (
                      <Tr key={district_name} fontFamily='revert'>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{district_name}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{village_name}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>
                            {role} - {name}
                          </Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>
                            {' '}
                            <Icon
                              w='24px'
                              h='24px'
                              me='5px'
                              color={
                                is_approved === '1' ? 'green.500' : 'red.500'
                              }
                              as={
                                is_approved === '1' ? MdCheckCircle : MdCancel
                              }
                            />
                            {is_approved === '1' ? 'Approve' : 'Pending'}
                          </Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Flex align='centre'>
                            <Tooltip
                              label='Detail data'
                              hasArrow
                              arrowSize={15}
                            >
                              <Button
                                leftIcon={<TfiList />}
                                variant='outline'
                                size='sm'
                                m='2px'
                                colorScheme='whatsapp'
                                onClick={() =>
                                  this.onGoDetail(
                                    user_id,
                                    village_id,
                                    district_id
                                  )
                                }
                              >
                                {' '}
                                Detail
                              </Button>
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          currentPage={currentPage}
          totalRecords={totalPage}
          pageLimit={parseInt(pageSize)}
          pageNeighbours={1}
          paginate={this.paginate}
          paginateBack={this.paginateBack}
          paginateFront={this.paginateFront}
        />
      </Box>

      // <Modal isCentered isOpen={isOpen} onClose={onClose}>
      //   {overlay}
      //   <ModalContent>
      //     <ModalHeader>Modal Title</ModalHeader>
      //     <ModalCloseButton />
      //     <ModalBody>
      //       <Text>Custom backdrop filters!</Text>
      //     </ModalBody>
      //     <ModalFooter>
      //       <Button onClick={onClose}>Close</Button>
      //     </ModalFooter>
      //   </ModalContent>
      // </Modal>
    );
  }
}

export default LaporanPage;
