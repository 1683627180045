import React, { Component } from 'react';
//assets
import {
  Box,
  Flex,
  Button,
  Spacer,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  SimpleGrid,
  Select,
  Tr,
  TableContainer,
  ButtonGroup,
  FormLabel,
	Tooltip,
} from '@chakra-ui/react';
import {
  MdModeEditOutline,
  MdDelete,
  MdCheckCircle,
  MdCancel,
} from 'react-icons/md';
import { TfiSettings, TfiFiles, TfiSearch, TfiPencil, TfiDownload, TfiList } from 'react-icons/tfi';
import { ToastContainer, toast } from 'react-toastify';
import Card from 'components/card/Card';
import Pagination from 'components/pagination/pagination';
import { USER_SERVICE } from 'services/user';
import { PLAN_SERVICE } from 'services/plan';


let us = localStorage.getItem('user');
let userProfile = JSON.parse(us);

class UserPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userData: [],
      isLoading: true,
      currentPage: 1,
      pageSize: 2,
      totalPage: 1,
      onClose: false,
      isOnceSubmit: false,
      villageId: userProfile?.user?.villageId,
      districtId: userProfile?.user?.districtId,
      cashflowData: [],
    }
  }

  changeHandler = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value,
    })
  }

  onGetData = (currentPage) => {
    const { villageId, districtId, pageSize } = this.state
    let user = localStorage.getItem('user')

    this.setState({
      isOnceSubmit: true,
    })

    const payload = {
      userId: JSON.parse(user)?.user.userId,
      districtId: districtId,
      villageId: villageId,
      size: pageSize,
      page: currentPage,
    }

    PLAN_SERVICE.get(payload)
      .then((res) => {
        if (res?.data?.code === 200) {
          if (res?.data?.values.length !== 0) {
            this.setState({
              cashflowData: res?.data?.values,
              isOnceSubmit: false,
              totalPage: res?.data?.paginate?.totalData,
            })
          } else {
            this.setState({
              cashflowData: res?.data?.values,
              totalPage: res?.data?.paginate?.totalData,
              isOnceSubmit: false,
            })
            toast.error('Data Tidak ada', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          }
        }
      })
      .catch((err) => {
        this.setState({
          isOnceSubmit: false,
        })
        toast.error('Data Tidak ada', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
  }

  onGoDetail = (id) => {
    let planId = id === undefined ? 'add' : id
    localStorage.setItem('uploadPlanId', planId)
    window.location.href = `/kpu/ngawi/admin/uploadDetail`
  }

  onDeleteData = (id) => {
    const payload = {
      userId: id,
    }
    USER_SERVICE.destroy(payload)
      .then((res) => {
        this.onGetDataUser(1)
        console.log(res)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  paginateFront = () => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1, isLoading: true })
    this.onGetData(currentPage + 1)
  }

  paginateBack = (data) => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1, isLoading: true })
    this.onGetData(currentPage - 1)
  }

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber, isLoading: true })
    this.onGetData(pageNumber)
  }

  componentDidMount() {
    const {currentPage} = this.state;

    this.onGetData(currentPage)
  }

  render() {
    const {
      userData,
      currentPage,
      totalPage,
      pageSize,
      onClose,
      monthNow,
      isOnceSubmit,
      cashflowData,
    } = this.state
    console.log(userData)
    let headerElement = ['Uraian', 'Status', 'action']

    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px' mb='15px'>
          <Box>
            <Card mb='10px'>
              <FormLabel>Filter </FormLabel>

              <FormLabel>PPK Widodaren</FormLabel>
              <Select
                placeholder='Desa'
                size='md'
                type='month'
                value={monthNow}
                name='monthNow'
                onChange={this.changeHandler}
              />
              <FormLabel mb='15px'></FormLabel>
              <Button
                onClick={this.onGetData}
                leftIcon={<TfiSearch />}
                isLoading={isOnceSubmit}
                loadingText='Loading...'
                colorScheme='telegram'
                variant='solid'>
                Show
              </Button>
            </Card>
          </Box>
          <Spacer></Spacer>
          {/* <Box>
            <Card mb='10px'>
              <FormLabel>Filter Bulan</FormLabel>
              <Input
                placeholder='Select Date and Time'
                size='md'
                type='month'
                value={monthNow}
                name='monthNow'
                onChange={this.changeHandler}
              />
              <FormLabel mb='15px'></FormLabel>
              <Button
                onClick={this.onGetData}
                leftIcon={<TfiSearch />}
                isLoading={isOnceSubmit}
                loadingText='Loading...'
                colorScheme='telegram'
                variant='solid'
              >
                Show
              </Button>
            </Card>
          </Box> */}
        </SimpleGrid>

        <Card
          direction='column'
          w='100%'
          px='0px'
          overflowX={{ sm: 'scroll', lg: 'hidden' }}>
          <TableContainer>
            <Table colorScheme='tomato' variant='striped'>
              <Thead>
                <Tr>
                  {headerElement.map((key, index) => {
                    return (
                      <Th key={index} fontFamily='revert'>
                        <Flex
                          justify='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'>
                          {key.toUpperCase()}
                        </Flex>
                      </Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {cashflowData.map(({ plan_id, plan_name, is_approved }) => {
                  console.log(plan_name, 'dbsj')
                  return (
                    <Tr key={plan_id} fontFamily='revert'>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        <Text>{plan_name}</Text>
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        <Text>
                          {' '}
                          <Icon
                            w='24px'
                            h='24px'
                            me='5px'
                            color={
                              is_approved === '1' ? 'green.500' : 'red.500'
                            }
                            as={is_approved === '1' ? MdCheckCircle : MdCancel}
                          />
                          {is_approved === '1' ? 'Approve' : 'Pending'}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        <Flex align='centre'>
													<Tooltip label='Detail data' hasArrow arrowSize={15}>
                          <Button
													leftIcon={<TfiList />}
													variant='outline'
                            size='sm'
                            m='2px'
                            colorScheme='whatsapp'
                            onClick={() => this.onGoDetail(plan_id)}>
                            {' '}
                            Detail
                          </Button>
													</Tooltip>
                        </Flex>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          currentPage={currentPage}
          totalRecords={totalPage}
          pageLimit={parseInt(pageSize)}
          pageNeighbours={1}
          paginate={this.paginate}
          paginateBack={this.paginateBack}
          paginateFront={this.paginateFront}
        />
      </Box>

      // <Modal isCentered isOpen={isOpen} onClose={onClose}>
      //   {overlay}
      //   <ModalContent>
      //     <ModalHeader>Modal Title</ModalHeader>
      //     <ModalCloseButton />
      //     <ModalBody>
      //       <Text>Custom backdrop filters!</Text>
      //     </ModalBody>
      //     <ModalFooter>
      //       <Button onClick={onClose}>Close</Button>
      //     </ModalFooter>
      //   </ModalContent>
      // </Modal>
    )
  }
}

export default UserPage;
