// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
	Spacer,
} from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React, { useState, useEffect } from 'react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from 'react-icons/md';
import CheckTable from 'views/admin/default/components/CheckTable';
import ComplexTablePPK from 'views/admin/default/components/ComplexTablePPK';
import ComplexTablePPS from 'views/admin/default/components/ComplexTablePPS1';
import AnggaranPPK from 'views/admin/default/components/AnggaranPPK';
import AnggaranPPS1 from 'views/admin/default/components/AnggaranPPS';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCardPPK from 'views/admin/default/components/PieCardPPK';
import PieCardPPS from 'views/admin/default/components/PieCardPPS';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import Mastercard from 'views/admin/default/components/Mastercard';
import {
  columnsDataCheck,
  columnsDataComplex,
} from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';
import { REPORT_SERVICE } from 'services/report';
import formatCurrency from 'utils/formatCurrency';

let month, year;
const dateNow = new Date();
month = dateNow.getMonth() + 1;
year = dateNow.getFullYear();

month = month.toString().padStart(2, '0');
let fixMonth = `${year}-${month}`;
export default function AdhocPage(props) {
	console.log(props, "propssss")
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');


  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, '2xl': 6 }}
        gap='20px'
        mb='20px'
      >
        <Mastercard anggaran={props.anggaranVal} />
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, '2xl': 6 }}
          gap='20px'
          mb='20px'
        >
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon
                    w='32px'
                    h='32px'
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name='Pengeluaran PPS'
            value={formatCurrency(props.pengeluaranVal ?? 0)}
            growth='Bulan ini'
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon
                    w='32px'
                    h='32px'
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name='Sisa Saldo'
            value={formatCurrency(props.sisaVal ?? 0)}
            growth='Dari Bulan Lalu'
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2, '2xl': 6 }}>
          <Spacer />
        </SimpleGrid>
      </SimpleGrid>
      {/* <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, '2xl': 6 }}
        gap='20px'
        mb='20px'
      >
        <AnggaranPPS1
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />

        <ComplexTablePPS
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
      </SimpleGrid> */}
    </Box>
  );
}
