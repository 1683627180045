import React, { Component } from 'react'
//assets
import {
  Box,
  Flex,
  Button,
  Spacer,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  TableContainer,
  ButtonGroup,
  Input,
} from '@chakra-ui/react'
import { MdModeEditOutline, MdDelete } from 'react-icons/md'
import ModalBasic from "components/modal";

// import { ToastContainer, toast } from 'react-toastify';
import Card from 'components/card/Card'
import Pagination from 'components/pagination/pagination'
import { DISTRICT_SERVICE } from 'services/district'

class DistrictPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      districtData: [],
      isLoading: false,
      currentPage: 1,
      pageSize: 1,
      totalPage: 1,
    }
  }

  onGetDataDistrict = (currentPage) => {
    console.log('for service')
    const { pageSize } = this.state
    const pagination = {
      size: pageSize,
      page: currentPage,
    }

    DISTRICT_SERVICE.get(pagination)
      .then((res) => {
        if (res?.data?.code === 200) {
          this.setState({
            districtData: res?.data?.values,
            totalPage: res?.data?.paginate?.totalData,
          })
        }
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  onGoToCreateOrModify = (id) => {
    let distId = id === undefined ? 'add' : id
    localStorage.setItem('districtId', distId)
    window.location.href = `/kpu/ngawi/admin/createOrModifyDistrict`
  }

  onDeleteData = (id) => {
    const payload = {
      districtId: id,
    }
    DISTRICT_SERVICE.destroy(payload)
      .then((res) => {
        this.onGetDataDistrict(1)
        console.log(res)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  paginateFront = () => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1, isLoading: true })
    this.onGetDataDistrict(currentPage + 1)
  }

  paginateBack = (data) => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1, isLoading: true })
    this.onGetDataDistrict(currentPage - 1)
  }

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber, isLoading: true })
    this.onGetDataDistrict(pageNumber)
  }

  componentDidMount() {
    const { currentPage } = this.state
    this.onGetDataDistrict(currentPage)
  }

  render() {
    const { districtData, currentPage, totalPage, pageSize } = this.state
    let headerElement = ['nama kecamatan', 'action']
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex minWidth='max-content' alignItems='center' gap='2' pb='5%'>
          <Box p='2'></Box>
          <Spacer />
          <ButtonGroup gap='2'>
            <Button
              colorScheme='red'
              fontWeight='500'
              p={5}
              onClick={() => this.onGoToCreateOrModify()}>
              Tambah Data kecamatan
            </Button>
          </ButtonGroup>
        </Flex>
        <Card
          direction='column'
          w='100%'
          px='0px'
          overflowX={{ sm: 'scroll', lg: 'hidden' }}>
          <TableContainer>
            <Table colorScheme='red' variant='striped'>
              <Thead>
                <Tr>
                  {headerElement.map((key, index) => {
                    return (
                      <Th key={index} fontFamily='revert'>
                        <Flex
                          justify='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'>
                          {key.toUpperCase()}
                        </Flex>
                      </Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {districtData.map(({ district_id, district_name }) => {
                  console.log(district_name)
                  return (
                    <Tr key={district_id} fontFamily='revert'>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        <Text>{district_name}</Text>
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        <Flex align='centre'>
                          <Button
                            size='sm'
                            m='2px'
                            colorScheme='whatsapp'
                            onClick={() =>
                              this.onGoToCreateOrModify(district_id)
                            }>
                            <Icon w='16px' h='16px' as={MdModeEditOutline} />
                          </Button>
                          <Button
                            size='sm'
                            m='2px'
                            colorScheme='red'
                            onClick={() => this.onDeleteData(district_id)}>
                            <Icon w='16px' h='16px' as={MdDelete} />
                          </Button>
                        </Flex>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          currentPage={currentPage}
          totalRecords={totalPage}
          pageLimit={parseInt(pageSize)}
          pageNeighbours={1}
          paginate={this.paginate}
          paginateBack={this.paginateBack}
          paginateFront={this.paginateFront}
        />
      </Box>
    )
  }
}

export default DistrictPage
