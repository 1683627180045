import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdSettings,
} from 'react-icons/md';
import {
  TfiSettings,
  TfiFiles,
  TfiDashboard,
  TfiPieChart,
  TfiCheck,
  TfiUser,
  TfiUpload,
	TfiBook,
} from 'react-icons/tfi';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import CreateOrModifyVillage from 'views/admin/village/createOrModifyVillage';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import VillagePage from 'views/admin/village';
import DistrictPage from 'views/admin/district';
import CreateOrModifyDistrict from 'views/admin/district/createOrModifyDistrict';
import CashflowPage from 'views/admin/cashflow';
import PlanPage from 'views/admin/cashflow/planfront';
import UserPage from 'views/admin/user';
import CreateOrModifyUser from 'views/admin/user/createOrModifyUser';
import CreateOrModifyCashflow from 'views/admin/cashflow/createOrModifyCashflow';
import LaporanPage from 'views/admin/laporan';
import ApprovedPage from 'views/admin/approval';
import UploadPage from 'views/admin/upload';
import UploadDetailPage from 'views/admin/upload/detail'
import ApprovalDetailPage from 'views/admin/approval/detail'
import CreateOrModifyPlanPage from 'views/admin/cashflow/createOrModifyPlan';
import DetailPlan from 'views/admin/approval/detail';
import DetailCashflow from 'views/admin/approval/detailcashflow';
import ChangePassword from 'views/admin/user/changePassword'
import DetailLPJ from 'views/admin/laporan/detail';
import DetailCashLPJ from 'views/admin/laporan/detailcashflowlpj';
import RekapPage from 'views/admin/rekap';
import DashboardAdhoc from 'views/admin/default/adhoc';

const user = localStorage.getItem('user');
const userRole = JSON.parse(user);

let routes = [
  {
    name: '',
    layout: '/kpu/ngawi/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
 
];

if (userRole?.user?.role === 'admin') {
  const superAdmin = [
    {
      name: 'Dashboard',
      layout: '/kpu/ngawi/admin',
      path: '/default',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: MainDashboard,
    },
    // {
    //   name: 'Desa',
    //   layout: '/kpu/ngawi/admin',
    //   path: '/village',
    //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    //   component: VillagePage,
    // },
    // {
    //   name: 'Kecamatan',
    //   layout: '/kpu/ngawi/admin',
    //   path: '/district',
    //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    //   component: DistrictPage,
    // },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/createOrModifyVillage',
      icon: <button disabled={true}></button>,
      component: CreateOrModifyVillage,
    },

    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/CreateOrModifyDistrict',
      icon: <button disabled={true}></button>,
      component: CreateOrModifyDistrict,
    },
    {
      name: 'Rencana Anggaran',
      layout: '/kpu/ngawi/admin',
      path: '/plan',
      icon: <Icon as={TfiFiles} width='20px' height='20px' color='inherit' />,
      component: PlanPage,
      secondary: true,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/CreateOrModifyCashflow',
      icon: <button disabled={true}></button>,
      component: CreateOrModifyCashflow,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/cashflow',
      icon: <button disabled={true}></button>,
      component: CashflowPage,
    },
    {
      name: 'Approval',
      layout: '/kpu/ngawi/admin',
      icon: <Icon as={TfiCheck} width='20px' height='20px' color='inherit' />,
      path: '/approval',
      component: ApprovedPage,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      icon: <button disabled={true}></button>,
      path: '/DetailPlan',
      component: DetailPlan,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      icon: <button disabled={true}></button>,
      path: '/DetailCashflow',
      component: DetailCashflow,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      icon: <button disabled={true}></button>,
      path: '/approvalDetail',
      component: ApprovalDetailPage,
    },
    {
      name: 'Approval LPJ',
      layout: '/kpu/ngawi/admin',
      icon: (
        <Icon as={TfiPieChart} width='20px' height='20px' color='inherit' />
      ),
      path: '/laporan',
      component: LaporanPage,
    },

    {
      name: 'Upload',
      layout: '/kpu/ngawi/admin',
      icon: <Icon as={TfiUpload} width='20px' height='20px' color='inherit' />,
      path: '/upload',
      component: UploadPage,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      icon: <button disabled={true}></button>,
      path: '/uploadDetail',
      component: UploadDetailPage,
    },
		{
      name: 'Rekap',
      layout: '/kpu/ngawi/admin',
      path: '/rekap',
      icon: <Icon as={TfiBook} width='20px' height='20px' color='inherit' />,
      component: RekapPage,
    },
    // {
    //   name: 'Aprroval',
    //   layout: '/kpu/ngawi/admin',
    //   path: '/profile',
    //   icon: <Icon as={TfiCheck} width='20px' height='20px' color='inherit' />,
    //   component: Profile,
    // },
    // {
    //   name: 'Master Data',
    //   layout: '/kpu/ngawi/admin',
    //   path: '/master',
    //   icon: (
    //     <Icon as={TfiSettings} width='20px' height='20px' color='inherit' />
    //   ),
    //   component: Profile,
    // },
    {
      name: 'User',
      layout: '/kpu/ngawi/admin',
      path: '/user',
      icon: <Icon as={TfiUser} width='20px' height='20px' color='inherit' />,
      component: UserPage,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/createOrModifyUser',
      icon: <button disabled={true}></button>,
      component: CreateOrModifyUser,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/detailLpj',
      icon: <button disabled={true}></button>,
      component: DetailLPJ,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/detailCashLpj',
      icon: <button disabled={true}></button>,
      component: DetailCashLPJ,
    },
    {
      name: 'Ubah Password',
      layout: '/kpu/ngawi/admin',
      path: '/changepassword',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: ChangePassword,
    },
  ];
	routes = [...routes, ...superAdmin];
	
}

if (userRole?.user?.role === 'viewer') {
  const ppk = [
    {
      name: 'Dashboard',
      layout: '/kpu/ngawi/admin',
      path: '/default',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: MainDashboard,
    },
    {
      name: 'Approval',
      layout: '/kpu/ngawi/admin',
      icon: <Icon as={TfiCheck} width='20px' height='20px' color='inherit' />,
      path: '/approval',
      component: ApprovedPage,
    },
    {
      name: 'Approval LPJ',
      layout: '/kpu/ngawi/admin',
      icon: (
        <Icon as={TfiPieChart} width='20px' height='20px' color='inherit' />
      ),
      path: '/laporan',
      component: LaporanPage,
    },
    
    {
      name: 'Ubah Password',
      layout: '/kpu/ngawi/admin',
      path: '/changepassword',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: ChangePassword,
    },
  ];
  routes = [...routes, ...ppk];
}

if (userRole?.user?.role === 'approval') {
  const ppk = [
    {
      name: 'Dashboard',
      layout: '/kpu/ngawi/admin',
      path: '/default',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: MainDashboard,
    },
    {
      name: 'Approval',
      layout: '/kpu/ngawi/admin',
      icon: <Icon as={TfiCheck} width='20px' height='20px' color='inherit' />,
      path: '/approval',
      component: ApprovedPage,
    },
    {
      name: 'Ubah Password',
      layout: '/kpu/ngawi/admin',
      path: '/changepassword',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: ChangePassword,
    },
  ];
  routes = [...routes, ...ppk];
}
if (userRole?.user?.role === 'ppk') {
  const ppk = [
    {
      name: 'Dashboard',
      layout: '/kpu/ngawi/admin',
      path: '/default',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: MainDashboard,
    },
    {
      name: 'Rencana Anggaran',
      layout: '/kpu/ngawi/admin',
      path: '/plan',
      icon: <Icon as={TfiFiles} width='20px' height='20px' color='inherit' />,
      component: PlanPage,
      secondary: true,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/cashflow',
      icon: <button disabled={true}></button>,
      component: CashflowPage,
    },
    {
      name: 'Upload',
      layout: '/kpu/ngawi/admin',
      icon: <Icon as={TfiUpload} width='20px' height='20px' color='inherit' />,
      path: '/upload',
      component: UploadPage,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      icon: <button disabled={true}></button>,
      path: '/uploadDetail',
      component: UploadDetailPage,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/CreateOrModifyCashflow',
      icon: <button disabled={true}></button>,
      component: CreateOrModifyCashflow,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/CreateOrModifyPlan',
      icon: <button disabled={true}></button>,
      component: CreateOrModifyPlanPage,
    },
    {
      name: 'Ubah Password',
      layout: '/kpu/ngawi/admin',
      path: '/changepassword',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: ChangePassword,
    },
  ];
  routes = [...routes, ...ppk];
}

if (userRole?.user?.role === 'pps') {
  const pps = [
    {
      name: 'Dashboard',
      layout: '/kpu/ngawi/admin',
      path: '/default',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: MainDashboard,
    },
    {
      name: 'Rencana Anggaran',
      layout: '/kpu/ngawi/admin',
      path: '/plan',
      icon: <Icon as={TfiFiles} width='20px' height='20px' color='inherit' />,
      component: PlanPage,
      secondary: true,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/cashflow',
      icon: <button disabled={true}></button>,
      component: CashflowPage,
    },
    {
      name: 'Upload',
      layout: '/kpu/ngawi/admin',
      icon: <Icon as={TfiUpload} width='20px' height='20px' color='inherit' />,
      path: '/upload',
      component: UploadPage,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      icon: <button disabled={true}></button>,
      path: '/uploadDetail',
      component: UploadDetailPage,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/CreateOrModifyCashflow',
      icon: <button disabled={true}></button>,
      component: CreateOrModifyCashflow,
    },
    {
      name: '',
      layout: '/kpu/ngawi/admin',
      path: '/CreateOrModifyPlan',
      icon: <button disabled={true}></button>,
      component: CreateOrModifyPlanPage,
    },
    {
      name: 'Ubah Password',
      layout: '/kpu/ngawi/admin',
      path: '/changepassword',
      icon: (
        <Icon as={TfiDashboard} width='20px' height='20px' color='inherit' />
      ),
      component: ChangePassword,
    },
  ];
  routes = [...routes, ...pps];
}


export default routes;
