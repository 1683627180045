import Axios from 'axios'
import { url } from 'config/APIShare'

const timeout = 12000
const p = 'post'
const token_type = 'Bearer'
const access_token = localStorage.getItem('user')

export const VILLAGE_SERVICE = {
  async get(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/village`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },

  async getById(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/villageById`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },

  async getVillageByDistrict(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/village/getByDistrict`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },

  async create(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/village/add`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },

  async update(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/village/update`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },
  
  async destroy(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/village/delete`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  },
}
