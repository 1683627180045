import React, { Component } from 'react';
import {
  Box,
  Flex,
  Button,
  Spacer,
  SimpleGrid,
  Select,
  Input,
  InputGroup,
  InputLeftAddon,
  FormLabel,
  Heading,
  Textarea,
} from '@chakra-ui/react';
import {
  TfiSaveAlt,
  TfiBackLeft,
} from 'react-icons/tfi';
// import { ToastContainer, toast } from 'react-toastify';
import Card from 'components/card/Card';
import Mastercard from 'views/admin/cashflow/Mastercard';
import { CASHFLOW_SERVICE } from 'services/cashflow';

let us = localStorage.getItem('user')
let userProfile = JSON.parse(us)

class CreateOrModifyCashflowPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      districtID: userProfile.user.districtId,
      districtName: userProfile.user.districtName,
      villageID: userProfile.user.villageId,
      villageName: userProfile.user.villageName,
      isOnceSubmit: false,
      codeRek: 0,
      description: '',
      amount: '',
      tax: '',
      userId: userProfile.user.userId,
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  onGoToBack = ()=> {
		window.location.href = `/kpu/ngawi/admin/cashflow`;
  };

  onSubmitCashflow = () => {
    const { districtID, villageID, codeRek, description, amount, tax, userId } =
      this.state;

    this.setState({
      isOnceSubmit: true,
    });
		const planId =localStorage.getItem('planId')
		
    const cashAct = localStorage.getItem('cahflowId');
    if (cashAct === 'add') {
      const payload = {
        districtId: districtID,
        villageId: villageID,
        paguId: codeRek,
        description: description,
        amount: amount,
        tax: tax,
        userId: userId,
				planId: planId,
				
      };
      CASHFLOW_SERVICE.create(payload)
        .then((res) => {
          console.log(res.data);
          this.setState({
            isOnceSubmit: false,
          });
          window.location.href = `/kpu/ngaw/admin/cashflow`;
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isOnceSubmit: false,
          });
        });
    } else {
      const payload = {
        districtId: districtID,
        villageId: villageID,
        paguId: codeRek,
        description: description,
        amount: amount,
        tax: tax,
        userId: userId,
        cashFlowId: cashAct,
      };

      CASHFLOW_SERVICE.update(payload)
        .then((res) => {
          console.log(res.data);
          this.setState({
            isOnceSubmit: false,
          });
          window.location.href = `/kpu/ngaw/admin/cashflow`;
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isOnceSubmit: false,
          });
        });
    }
  };

  onGetData = () => {
    const cashAct = localStorage.getItem('cahflowId');
    if (cashAct !== 'add') {
      const payload = {
        cashFlowId: cashAct,
      };
      CASHFLOW_SERVICE.getById(payload)
        .then((res) => {
          console.log(res.data, 'dfnkadfnkd');
          if (res.data.code === 200) {
            this.setState({
              districtID: res.data.values[0].district_id,
              villageID: res.data.values[0].village_id,
              codeRek: res.data.values[0].id_pagu,
              description: res.data.values[0].description,
              amount: res.data.values[0].amount,
              tax: res.data.values[0].tax,
              userId: res.data.values[0].user_id,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidMount() {
    this.onGetData();
  }

  render() {
    let us = localStorage.getItem('user');
    let userProfile = JSON.parse(us);
    const {
      districtName,
      villageName,
      isOnceSubmit,
      codeRek,
      description,
      amount,
      tax,
    } = this.state;
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid>
          <Heading as='h4' size='xl' mb='20px'></Heading>
        </SimpleGrid>

        <Box>
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='5px'>
            <Card mb='10px'>
              <Box>
                {userProfile?.user?.role == 'ppk' ? (
                  <FormLabel pl={3}>PPK</FormLabel>
                ) : (
                  <Box></Box>
                )}
                <Box pl={3}>{districtName}</Box>
                {userProfile?.user?.role == 'pps' ? (
                  <FormLabel pl={3}></FormLabel>
                ) : (
                  <Box></Box>
                )}
                {userProfile?.user?.role == 'pps' ? (
                  <Box pl={3}>{villageName}</Box>
                ) : (
                  <Box></Box>
                )}
                <FormLabel></FormLabel>
                <Select
                  placeholder='Pilih Kode Rekening'
                  name='codeRek'
                  value={codeRek}
                  onChange={this.changeHandler}
                >
                  <option value='1'>521211 - Belanja Bahan</option>
                  <option value='2'>521213 - Honor Output Kegiatan</option>
                  <option value='3'>
                    521219 - Belanja Barang Non Operasional lainnya
                  </option>
                </Select>
                <FormLabel></FormLabel>
                <Textarea
                  required
                  value={description}
                  name='description'
                  variant='outline'
                  placeholder='Uraian Kegiatan'
                  onChange={this.changeHandler}
                />
                <FormLabel></FormLabel>
                <InputGroup>
                  <InputLeftAddon children='Rp.' />
                  <Input
                    type='number'
                    placeholder='Jumlah'
                    value={amount}
                    name='amount'
                    onChange={this.changeHandler}
                  />
                </InputGroup>
                <FormLabel></FormLabel>
                {/* <InputGroup>
                  <InputLeftAddon children='Rp.' />
                  <Input
                    type='number'
                    placeholder='Pajak'
                    value={tax}
                    name='tax'
                    onChange={this.changeHandler}
                  />
                </InputGroup> */}

                <FormLabel mb='30px'></FormLabel>
                <Flex>
                  <Button
                    onClick={this.onSubmitCashflow}
                    leftIcon={<TfiSaveAlt />}
                    colorScheme='teal'
                    variant='solid'
                    isLoading={isOnceSubmit}
                    loadingText='Loading...'
                  >
                    Simpan
                  </Button>
                  <Spacer />
                  <Button
                    onClick={this.onGoToBack}
                    leftIcon={<TfiBackLeft />}
                    colorScheme='green'
                    variant='outline'
                    isLoading={isOnceSubmit}
                    loadingText='Loading...'
										
                  >
                    Batal
                  </Button>
                </Flex>
              </Box>
              <Spacer></Spacer>
              <Box></Box>
            </Card>
            <Card mb='10px'>
              <Mastercard />
            </Card>
          </SimpleGrid>
        </Box>
      </Box>
    );
  }
}

export default CreateOrModifyCashflowPage;
