import React, { Component } from 'react';
//assets
import {
  Box,
  Flex,
  Button,
  Spacer,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  SimpleGrid,
  Select,
  Tr,
  Input,
  TableContainer,
  ButtonGroup,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import {
  MdModeEditOutline,
  MdDelete,
  MdCheckCircle,
  MdCancel,
} from 'react-icons/md';
import { TfiSearch, TfiDownload, TfiList, TfiPrinter } from 'react-icons/tfi';
// import { ToastContainer, toast } from 'react-toastify';
import Card from 'components/card/Card';
import Pagination from 'components/pagination/pagination';
import { USER_SERVICE } from 'services/user';
import { showDataMaster } from 'utils/showDataMaster';
import { VILLAGE_SERVICE } from 'services/village';
import formatCurrency from 'utils/formatCurrency';
import { REKAP_SERVICE } from 'services/rekap';
import { getMonthName, getYears, getFullDate } from 'utils/dateMaster';
import { GENERATE_SERVICE } from 'services/generate-pdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

let d = localStorage.getItem('districtValue');
let district = JSON.parse(d);

let month, year;
const dateNow = new Date();
month = dateNow.getMonth() + 1;
year = dateNow.getFullYear();

month = month.toString().padStart(2, '0');
let fixMonth = `${year}-${month}`;

class RekapPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rekapData: [],
      totalOfMonth: [],
      isLoading: true,
      currentPage: 1,
      pageSize: 2,
      totalPage: 1,
      onClose: false,
      monthNow: fixMonth,
      isOnceSubmit: false,
      districtVal: district,
      districtId: '',
      villageVal: [],
      villageid: '',
      total: '',
      role: '',
    }
  }

  changeHandler = (event) => {
    const name = event.target.name
    const value = event.target.value
    console.log(value, 'dk', name, 'name')
    this.setState({
      [name]: value,
    })

    // if (name === 'role' || name === 'monthNow') {
    //   this.onGetData(value);
    // }
  }

  onGoDetail = (userId, villageId, districtId) => {
    localStorage.setItem('userId', userId)
    localStorage.setItem('villageId', villageId)
    localStorage.setItem('districtId', districtId)

    window.location.href = `/kpu/ngawi/admin/DetailPlan`
  }

  onGetDataVillageByDis = (districtVal) => {
    let payload = {
      districtId: districtVal,
    }
    VILLAGE_SERVICE.getVillageByDistrict(payload)
      .then((res) => {
        this.setState({
          villageVal: res?.data?.values,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onGetData = () => {
    const { monthNow, role, pageSize } = this.state
    const payload = {}

    REKAP_SERVICE.get(payload)
      .then((res) => {
        this.setState({
          rekapData: res.data.values,
        })
        this.onModifyDataRekap(res.data.values)
        console.log(res.data, 'asasa')
      })
      .catch((err) => {
        console.log(err, 'jdfdj')
      })
  }

  paginateFront = () => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1, isLoading: true })
    this.onGetData(currentPage + 1)
  }

  paginateBack = (data) => {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1, isLoading: true })
    this.onGetData(currentPage - 1)
  }

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber, isLoading: true })
    this.onGetData(pageNumber)
  }

  onGeneratePDF = () => {
    let us = localStorage.getItem('user')
    let userProfile = JSON.parse(us)
    const { approvalVal } = this.state

    let payload = {
      bodyPayload: {
        values: approvalVal,
        tahun: getYears(new Date()),
        bulan: getMonthName(new Date()),
        penyelenggara: 'KPU Kabupaten Ngawi',
        kecamatan: userProfile?.user?.districtName,
      },
      footer: {
        wilayah: 'NGAWI',
        tanggal: getFullDate(new Date()),
        // atasnama: `Ketua ${userProfile?.user?.role} ${userProfile?.user?.districtName}`,
        atasnama: 'KPU Kabupaten Ngawi',
      },
      jenis: 'approval',
    }

    GENERATE_SERVICE.generatePDF(payload)
      .then((res) => {
        if (res.data.code === 200) {
          const linkSource = `data:application/pdf;base64,${res.data.values}`
          const downloadLink = document.createElement('a')
          const fileName = `Rencana Anggaran - ${getFullDate(new Date())}`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onModifyDataRekap = (data) => {
    const Jan = data.reduce((accumulator, object) => {
      return accumulator + object.Jan
    }, 0)
    const Feb = data.reduce((accumulator, object) => {
      return accumulator + object.Feb
    }, 0)
    const Mar = data.reduce((accumulator, object) => {
      return accumulator + object.Mar
    }, 0)
    const Apr = data.reduce((accumulator, object) => {
      return accumulator + object.Apr
    }, 0)
    const Mei = data.reduce((accumulator, object) => {
      return accumulator + object.Mei
    }, 0)
    const Jun = data.reduce((accumulator, object) => {
      return accumulator + object.Jun
    }, 0)
    const Jul = data.reduce((accumulator, object) => {
      return accumulator + object.Jul
    }, 0)
    const Agus = data.reduce((accumulator, object) => {
      return accumulator + object.Agus
    }, 0)
    const Sept = data.reduce((accumulator, object) => {
      return accumulator + object.Sept
    }, 0)
    const Okt = data.reduce((accumulator, object) => {
      return accumulator + object.Okt
    }, 0)
    const Nov = data.reduce((accumulator, object) => {
      return accumulator + object.Nov
    }, 0)
    const Des = data.reduce((accumulator, object) => {
      return accumulator + object.Des
    }, 0)
    const Total = data.reduce((accumulator, object) => {
      return accumulator + object.Total
    }, 0)
    const Sisa = data.reduce((accumulator, object) => {
      return accumulator + object.Sisa_Anggaran
    }, 0)

    const totalOfMonth = [
      {
        Jan: Jan,
        Feb: Feb,
        Mar: Mar,
        Apr: Apr,
        Mei: Mei,
        Jun: Jun,
        Jul: Jul,
        Agus: Agus,
        Sept: Sept,
        Okt: Okt,
        Nov: Nov,
        Des: Des,
        Total: Total,
        Sisa: Sisa
      },
    ]
    this.setState({totalOfMonth: totalOfMonth})
  }

  _exportPdfRekap = () => {
    const q =document.querySelector("#rekap");

    html2canvas(q)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        console.log(imgData, 'cas');

        let pdf = new jsPDF("landscape", "mm", "letter");
        pdf.addImage(imgData, 'JPEG', 0, 0);
				//  pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
        // pdf.output('dataurlnewwindow');
        pdf.save("Rekap Data.pdf");
      });
}

  async componentDidMount() {
    await this.onGetData()
  }

  render() {
    const {
      rekapData,
      currentPage,
      totalPage,
      pageSize,
      districtVal,
      districtId,
      monthNow,
      villageVal,
      villageId,
      isOnceSubmit,
      total,
      role,
      totalOfMonth
    } = this.state
    console.log(rekapData, 'aksjm')
    let headerElement = [
      'Badan Adhoc',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Mei',
      'Jun',
      'Jul',
      'Agu',
      'Sep',
      'Okt',
      'Nov',
      'Des',
      'Jumlah',
      'Sisa Anggaran',
    ]

    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        {/* <SimpleGrid gap='20px' mb='15px'></SimpleGrid>
				<Spacer />
          <SimpleGrid gap='20px' mb='15px'>
            <Text ontSize='50px' color='tomato' as='b'>
              REKAPITULASI LAPORAN REALISASI PERTANGGUNGJAWABAN PENGGUNAAN
              ANGGARAN PEMILU 2024
            </Text>
            <Text>BADAN ADHOC PENYELENGGARA PEMILIHAN UMUM</Text>
						<Text>KABUPATEN NGAWI</Text>
          </SimpleGrid> */}

        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap='20px' mb='15px'>
          <Card mb='10px'>
            <Box>
              <FormLabel></FormLabel>
              <Select
                placeholder='Level'
                name='role'
                value={role}
                onChange={this.changeHandler}
              >
                <option values='ppk'>PPK</option>
                <option values='pps'>PPS</option>
              </Select>
            </Box>
            <FormLabel></FormLabel>
            <Button
              colorScheme='red'
              variant='outline'
              leftIcon={<TfiDownload />}
              p={5}
              size='sm'
              onClick={() => this.onGetData(currentPage)}
            >
              search
            </Button>
          </Card>
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap='20px' mb='15px'>
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />
          <Box>
            <ButtonGroup gap='2'>
              <Button
                colorScheme='red'
                variant='outline'
                leftIcon={<TfiDownload />}
                p={5}
                size='sm'
                onClick={() => this._exportPdfRekap()}
              >
                PDF
              </Button>
            </ButtonGroup>
            <ButtonGroup gap='2'>
              <Button
                colorScheme=''
                variant='outline'
                leftIcon={<TfiPrinter />}
                p={5}
                size='sm'
                onClick={() => this._exportPdfRekap()}
              >
                Print
              </Button>
            </ButtonGroup>
          </Box>
        </SimpleGrid>

        <Card
          direction='column'
          w='100%'
          px='0px'
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <TableContainer id='rekap'>
            <Table colorScheme='tomato' variant='striped'>
              <Thead>
                <Tr>
                  {headerElement.map((key, index) => {
                    return (
                      <Th key={index} fontFamily='revert'>
                        <Flex
                          justify='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                        >
                          {key.toUpperCase()}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {rekapData.map(
                  ({
                    name,
                    Jan,
                    Feb,
                    Mar,
                    Apr,
                    Mei,
                    Jun,
                    Jul,
                    Agus,
                    Sept,
                    Okt,
                    Nov,
                    Des,
                    Sisa_Anggaran,
                    Total,
                  }) => {
                    console.log(name, 'makan');
                    return (
                      <Tr key={name} fontFamily='revert'>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          fontWeight='extrabold'
                        >
                          <Text>{name}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Jan}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Feb}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Mar}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Apr}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Mei}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Jun}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Jul}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Agus}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Sept}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Okt}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Nov}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Des}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Total}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                        >
                          <Text>{Sisa_Anggaran}</Text>
                        </Td>
                      </Tr>
                    );
                  }
                )}

                {totalOfMonth.map(
                  ({
                    Jan,
                    Feb,
                    Mar,
                    Apr,
                    Mei,
                    Jun,
                    Jul,
                    Agus,
                    Sept,
                    Okt,
                    Nov,
                    Des,
                    Sisa,
                    Total,
                  }) => {
                    return (
                      <Tr fontFamily='revert'>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          fontWeight='extrabold'
                        >
                          <Text>Total Bulanan</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Jan}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Feb}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Mar}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Apr}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Mei}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Jun}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Jul}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Agus}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Sept}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Okt}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Nov}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Des}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Total}</Text>
                        </Td>
                        <Td
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'
                          color='red'
                          fontWeight='extrabold'
                        >
                          <Text>{Sisa}</Text>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          currentPage={currentPage}
          totalRecords={totalPage}
          pageLimit={parseInt(pageSize)}
          pageNeighbours={1}
          paginate={this.paginate}
          paginateBack={this.paginateBack}
          paginateFront={this.paginateFront}
        />
      </Box>

      // <Modal isCentered isOpen={isOpen} onClose={onClose}>
      //   {overlay}
      //   <ModalContent>
      //     <ModalHeader>Modal Title</ModalHeader>
      //     <ModalCloseButton />
      //     <ModalBody>
      //       <Text>Custom backdrop filters!</Text>
      //     </ModalBody>
      //     <ModalFooter>
      //       <Button onClick={onClose}>Close</Button>
      //     </ModalFooter>
      //   </ModalContent>
      // </Modal>
    );
  }
}

export default RekapPage;
