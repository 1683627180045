import Axios from 'axios';
import { url } from 'config/APIShare';

const timeout = 12000;
const p = 'post';
const token_type = 'Bearer';
const access_token = localStorage.getItem('user');

export const REPORT_SERVICE = {
  async reportPPK(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/report/reportPPK`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async reportPPS(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/report/reportPPS`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async anggaranPPK(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/report/anggaranPPK`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async anggaranPPS(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/report/anggaranPPS`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async rincianSum(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/report/rinciansum`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async anggaran(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/report/anggaran`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },
};

