const getMonthName = (val) => {
  const month = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]
  let monthName = month[val.getMonth()]

  return monthName
}

const getYears = (val) => {
  return val.getFullYear()
}

const getFullDate = (val) => {
  const p = val.getFullYear()
  const r = val.getDate()
  const months = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]
  const monthName = months[val.getMonth()]

  return `${r} ${monthName} ${p}`
}

export { getMonthName, getYears, getFullDate }
