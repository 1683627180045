import Axios from 'axios';
import { url } from 'config/APIShare';

const timeout = 12000;
const p = 'post';
const token_type = 'Bearer';
const access_token = localStorage.getItem('user');

export const CASHFLOW_SERVICE = {
  async get(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/getData`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async getApp(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/getApp`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async approved(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/isApprove`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async approvedLpj(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/approveLpj`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async addComment(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/comment`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async approvedLPJ(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/approvedLPJ`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async getById(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/getById`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async create(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/storeFromUser`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async update(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/updateRAB`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },

  async destroy(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/cashFlow/deleteRAB`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    });
  },
};
