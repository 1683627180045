// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React, { useState, useEffect } from 'react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from 'react-icons/md';
import CheckTable from 'views/admin/default/components/CheckTable';
import ComplexTablePPK from 'views/admin/default/components/ComplexTablePPK';
import ComplexTablePPS from 'views/admin/default/components/ComplexTablePPS1';
import AnggaranPPK from 'views/admin/default/components/AnggaranPPK';
import AnggaranPPS1 from 'views/admin/default/components/AnggaranPPS';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCardPPK from 'views/admin/default/components/PieCardPPK';
import PieCardPPS from 'views/admin/default/components/PieCardPPS';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import {
  columnsDataCheck,
  columnsDataComplex,
} from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';
import { REPORT_SERVICE } from 'services/report';
import formatCurrency from 'utils/formatCurrency';
import AdhocPage from 'views/admin/default/adhoc';
import Mastercardadmin from 'views/admin/default/components/Mastercardadmin';
// import adhoc from './adhoc';

let month, year;
const dateNow = new Date();
month = dateNow.getMonth() + 1;
year = dateNow.getFullYear();

month = month.toString().padStart(2, '0');
let fixMonth = `${year}-${month}`;
export default function UserReports() {
  // const [isOpen, setIsOpen] = useState(false)
  //   // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const [sumPPK, setSumPPK] = useState(0)
	const [sumPPS, setSumPPS] = useState(0);
	const [sumAll, setSumAll] = useState(0);
	const [anggaranVal, SetAnggaranVal] =useState(0);
	const [pengeluaranVal, SetPengeluaranVal] = useState(0);
	const [sisaVal, SetSisaVal] = useState(0);
	const [isAdmin, setIsAdmin] = useState(true);
  const us = localStorage.getItem('user')
	
  const pass = JSON.parse(us).user.password
  const change = pass === 'f865b53623b121fd34ee5426c792e5c33af8c227' ? true : false
  // 	// console.log(change)
  
	const onDefineUser = (val) => {
		if(val !== 'admin'){
			setIsAdmin(false)
		}
	}

	useEffect(() => {
		onDefineUser(JSON.parse(us).user.role);
    if(change){
  		toast.warn('Mohon Ganti Password Anda!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  	}
		if(JSON.parse(us).user.role === 'admin'){
		let payload = {
			month: fixMonth
		}
		REPORT_SERVICE.rincianSum(payload)
		.then(res =>{
			if(res.data.code === 200){
				setSumPPK(res.data.values.sumPPK)
				setSumPPS(res.data.values.sumPPS);
				setSumAll(res.data.values.sumPPK + res.data.values.sumPPS);
			}
		}).catch(err=>{
			console.log('====================================');
			console.log(err);
			console.log('====================================');
		})
		} else {
			let payload = {
        month: fixMonth,
        userId: JSON.parse(us).user.userId,
      };
      REPORT_SERVICE.anggaran(payload)
        .then((res) => {
          if (res.data.code === 200) {
            SetAnggaranVal(res.data.values.anggaran)
						SetPengeluaranVal(res.data.values.pengeluaran);
						SetSisaVal(res.data.values.sisa);
          }
        })
        .catch((err) => {
          console.log('====================================');
          console.log(err);
          console.log('====================================');
        });
		}
	}, [])

  return (
    <>
      {isAdmin ? (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
          <SimpleGrid columns={[2, 2, 2]} gap='20px' mb='20px'>
            <Mastercardadmin />
          </SimpleGrid>
          <SimpleGrid columns={[2, 2, 3]} gap='20px' mb='20px'>
            <MiniStatistics
              growth='Bulan ini'
              name='Sisa Anggaran'
              value={formatCurrency(sumAll)}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon
                      w='32px'
                      h='32px'
                      as={MdAttachMoney}
                      color={brandColor}
                    />
                  }
                />
              }
              name='Pengeluaran PPK'
              value={formatCurrency(sumPPK)}
              growth='Bulan ini'
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon
                      w='32px'
                      h='32px'
                      as={MdAttachMoney}
                      color={brandColor}
                    />
                  }
                />
              }
              name='Pengeluaran PPS'
              value={formatCurrency(sumPPS)}
              growth='Bulan ini'
            />
          </SimpleGrid>

          {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <PieCardPPK />
        <PieCardPPS />
      </SimpleGrid> */}

          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2, '2xl': 6 }}
            gap='20px'
            mb='20px'
          >
            <AnggaranPPK
              columnsData={columnsDataComplex}
              tableData={tableDataComplex}
            />

            <ComplexTablePPK
              columnsData={columnsDataComplex}
              tableData={tableDataComplex}
            />
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2, '2xl': 6 }}
            gap='20px'
            mb='20px'
          >
            <AnggaranPPS1
              columnsData={columnsDataComplex}
              tableData={tableDataComplex}
            />

            <ComplexTablePPS
              columnsData={columnsDataComplex}
              tableData={tableDataComplex}
            />
          </SimpleGrid>
        </Box>
      ) : (
        <AdhocPage
          anggaranVal={anggaranVal}
          pengeluaranVal={pengeluaranVal}
          sisaVal={sisaVal}
        />
      )}
    </>
  );
}
