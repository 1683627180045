import Axios from 'axios'
import { url } from 'config/APIShare'

const timeout = 12000
const p = 'post'
const token_type = 'Bearer'
const access_token = localStorage.getItem('user')

export const GENERATE_SERVICE = {
  async generatePDF(request) {
    return Axios(`${url}/cashManagementSystem/api/v1/pdf/generate`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token_type} ${JSON.parse(access_token).accessToken}`,
      },
      timeout,
    })
  }
}
