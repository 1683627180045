import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useState,useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { REPORT_SERVICE } from 'services/report';
import formatCurrency from 'utils/formatCurrency';
import formatDate from 'utils/formatDate'
export default function ColumnsTable(props) {
	const [value, setValue] = useState([])
	// console.log(columnsData	, tableData)
	const header = [
    {
      Header: 'name',
      accessor: 'name',
    },

    {
      Header: 'created_at',
      accessor: 'created_at',
    },
    {
      Header: 'total',
      accessor: 'total',
    },
    {
      Header: 'is_approved',
      accessor: 'is_approved',
    },
  ];

	const onGetData = () => {
		let month, year;
    const dateNow = new Date();
    month = dateNow.getMonth() + 1;
    year = dateNow.getFullYear();

    month = month.toString().padStart(2, '0');
    let fixMonth = `${year}-${month}`;
		let payload = {
      month: fixMonth,
    };
		REPORT_SERVICE.anggaranPPK(payload)
		.then(res =>{
			setValue(res.data.values)
			console.log(res,'d')
		}).catch(err=>{
			console.log(err);
		})
	}
	
	useEffect(() => {
			onGetData();
	}, [])


  const columns = useMemo(() => header, []);
  const data = useMemo(() => value, [value]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='10px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Anggaran PPK
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === 'name') {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === 'is_approved') {
                    data = (
                      <Flex align='center'>
                        <Icon
                          w='24px'
                          h='24px'
                          me='5px'
                          color={
                            cell.value === '1'
                              ? 'green.500'
                              : cell.value === '0'
                              ? 'orange.500'
                              : null
                          }
                          as={
                            cell.value === '1'
                              ? MdCheckCircle
                              : cell.value === '0'
                              ? MdOutlineError
                              : null
                          }
                        />
                        {/* <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value === '1' ? 'Approve' : 'Pending / Not Approve'}
                        </Text> */}
                      </Flex>
                    );
                  } else if (cell.column.Header === 'total') {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {formatCurrency(cell.value)}
                      </Text>
                    );
                  } else if (cell.column.Header === 'created_at') {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {formatDate(cell.value)}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      maxH='30px !important'
                      py='8px'
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
