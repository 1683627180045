import React from "react";

// Chakra imports
import { Flex, Box, Icon, Text, Spacer, Image, Heading } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

// Assets
import bgMastercard from "assets/img/dashboards/card.jpeg";
import logoKpu from 'assets/img/dashboards/kpu.png';
import { RiMastercardFill } from "react-icons/ri";

export default function Banner(props) {
  const { exp, cvv, number, ...rest } = props;

  // Chakra Color Mode
  return (
    <Card
      backgroundImage={bgMastercard}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      alignSelf='center'
      w={{ base: '100%', md: '30%', xl: '100%' }}
      bgPosition='10%'
      mx='auto'
      p='20px'
      {...rest}
    >
      <Flex direction='column' color='white' h='100%' w='100%'>
        <Flex justify='space-between' align='center' mb='37px'>
          <Text fontSize='2xl' fontWeight='bold'>
            Komisi Pemilihan Umum
          </Text>
          <p></p>
          <Text fontSize='2xl' fontWeight='bold'></Text>
          <Text fontSize='2xl' fontWeight='bold'></Text>
          <Icon as={logoKpu} w='48px' h='auto' />
          <Image src='assets/img/dashboards/kpu.png' w='48px' h='auto' />
        </Flex>
        <Spacer />
        <Flex direction='column'>
          <Box>
            <Heading fontSize={{ sm: '3xl', lg: '3lg', xl: '3xl' }} fontWeight='bold'>
             Rp. 20.750.000
            </Heading>
          </Box>
          <Flex mt='14px'>
            <Flex direction='column' me='34px'>
              <Text fontSize='xs'>Bulan</Text>
              <Text fontSize='sm' fontWeight='500'>
                03
              </Text>
            </Flex>
            <Flex direction='column'>
              <Text fontSize='xs'>Tahun</Text>
              <Text fontSize='sm' fontWeight='500'>
                2023
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
