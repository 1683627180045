import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Box, Flex, Button, Icon, Heading } from '@chakra-ui/react'
import { MdOutlineSkipNext, MdOutlineSkipPrevious } from 'react-icons/md'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

class Pagination extends Component {
  constructor(props) {
    super(props)
    const { totalRecords, pageLimit, pageNeighbours, currentPage } = this.props
    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30
    this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0

    this.pageNeighbours =
      typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit)

    this.state = { currentPage: currentPage, totalRecord: totalRecords ?? 0 }
  }

  componentDidMount() {
    this.gotoPage(1)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.totalRecords !== this.props.totalRecords) {
      this.setState({ totalRecord: this.props.totalRecords })
    }
  }

  gotoPage = (page) => {
    const { onPageChanged = (f) => f } = this.props

    const currentPage = Math.max(
      0,
      Math.min(page, Math.ceil(this.state.totalRecord / this.pageLimit)),
    )

    const paginationData = {
      currentPage,
      totalPages: Math.ceil(this.state.totalRecord / this.pageLimit),
      pageLimit: this.pageLimit,
      totalRecords: this.state.totalRecord,
    }

    this.setState({ currentPage }, () => onPageChanged(paginationData))
  }

  handleClick = (page) => (evt) => {
    const { paginate } = this.props
    evt.preventDefault()
    this.gotoPage(page)
    paginate(page)
  }

  handleMoveLeft = (evt) => {
    const { paginateBack } = this.props
    const { currentPage } = this.state
    evt.preventDefault()
    this.setState({ currentPage: currentPage - 1 })
    paginateBack()
  }

  handleMoveRight = (evt) => {
    const { paginateFront } = this.props
    const { currentPage } = this.state
    evt.preventDefault()
    this.setState({ currentPage: currentPage + 1 })
    paginateFront()
  }

  fetchPageNumbers = () => {
    const currentPage = this.state.currentPage
    const pageNeighbours = this.pageNeighbours

    const totalNumbers = this.pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (Math.ceil(this.state.totalRecord / this.pageLimit) > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(
        Math.ceil(this.state.totalRecord / this.pageLimit) - 1,
        currentPage + pageNeighbours,
      )

      let pages = range(startPage, endPage)

      const hasLeftSpill = startPage > 2
      const hasRightSpill =
        Math.ceil(this.state.totalRecord / this.pageLimit) - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, Math.ceil(this.state.totalRecord / this.pageLimit)]
    }

    return range(1, Math.ceil(this.state.totalRecord / this.pageLimit))
  }

  render() {
    if (
      !this.state.totalRecord ||
      Math.ceil(this.state.totalRecord / this.pageLimit) === 1
    )
      return null

    const { currentPage, totalRecord } = this.state
    const pages = this.fetchPageNumbers()
    const { pageLimit } = this.props

    return (
      <Fragment>
        <Box w='100%' pt='2'>
          <Box>
            <Heading size='xs'>
              Showing
              <span> {currentPage * pageLimit - pageLimit}</span>
              <span> to </span>
              <span> {currentPage * pageLimit} </span>
              of
              <span> {totalRecord} </span>
              results
            </Heading>
          </Box>
          <Flex pt='2'>
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <div key={index}>
                    <Button
                      variant='solid'
                      size='xs'
                      mr='1'
                      colorScheme='whatsapp'
                      onClick={this.handleMoveLeft}>
                      <Icon w='16px' h='16px' as={MdOutlineSkipPrevious} />
                    </Button>
                  </div>
                )

              if (page === RIGHT_PAGE)
                return (
                  <div key={index}>
                    <Button
                      variant='solid'
                      size='xs'
                      mr='1'
                      colorScheme='whatsapp'
                      onClick={this.handleMoveRight}>
                      <Icon w='16px' h='16px' as={MdOutlineSkipNext} />
                    </Button>
                  </div>
                )

              return (
                <div key={index}>
                  <Button
                    color={currentPage === page ? 'white' : 'black'}
                    colorScheme={currentPage === page ? 'red' : 'gray'}
                    variant='solid'
                    size='xs'
                    mr='1'
                    onClick={this.handleClick(page)}>
                    {page}
                  </Button>
                </div>
              )
            })}
          </Flex>
        </Box>
      </Fragment>
    )
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
}

export default Pagination
