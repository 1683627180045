import React, { Component } from 'react';
import {
  Box,
  Flex,
  Button,
  Spacer,
  SimpleGrid,
  Select,
  Input,
  FormLabel,
  Heading,
} from '@chakra-ui/react';
import {
  TfiSaveAlt,
  TfiBackLeft,
} from 'react-icons/tfi';
// import { ToastContainer, toast } from 'react-toastify';
import Card from 'components/card/Card';
import { USER_SERVICE } from 'services/user';
import { showDataMaster } from 'utils/showDataMaster';

let d = localStorage.getItem('districtValue')
let district = JSON.parse(d)
let v = localStorage.getItem('villageValue')
let village = JSON.parse(v)

class CreateOrModifyuserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      districVal: district,
      villageVal: village,
      districtId: 1,
      villageId: 1,
      name: '',
      userName: '',
      password:'',
      phone: '',
      role:'',
      isActive: '',
      email: '',
      isOnceSubmit: false,
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

onGoToBack = ()=> {
		window.location.href = `/kpu/ngawi/admin/user`;
  };

  onSubmitOrUpdateData = () => {
    const {
      districtId,
      villageId,
      name,
      userName,
      password,
      phone,
      role,
      isActive,
      email,
    } = this.state;

    const userAct = localStorage.getItem('userId');

    this.setState({
      isOnceSubmit: true,
    });

    if (userAct === 'add') {
      const payload ={
        districtId: districtId,
        villageId: villageId,
        name: name,
        username: userName,
        password: password,
        phone: phone,
        role: role,
        isActive: isActive,
        email: email,
      }

      USER_SERVICE.add(payload)
        .then((res) => {
          console.log(res.data);
          this.setState({
            isOnceSubmit: false,
          });
          window.location.href = `/kpu/ngawi/admin/user`;
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isOnceSubmit: false,
          });
        });
    } else {
      console.log('edit');

      const payload ={
        userId: userAct,
        districtId: districtId,
        villageId: villageId,
        name: name,
        username: userName,
        password: password,
        phone: phone,
        role: role,
        isActive: isActive,
        email: email,
      }
      USER_SERVICE.update(payload)
        .then((res) => {
          this.setState({
            isOnceSubmit: false,
          });
          window.location.href = `/kpu/ngawi/admin/user`;
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isOnceSubmit: false,
          });
        });

    }
  };

  onGetData = () => {
    const villId = localStorage.getItem('userId');
    if (villId !== 'add') {
      const payload = {
        userId: villId,
      };
      USER_SERVICE.getById(payload)
        .then((res) => {
          console.log(res.data);
          if (res.data.code === 200) {
            this.setState({
              districtId: res.data.values[0].district_id,
              villageId: res.data.values[0].village_id,
              name: res.data.values[0].name,
              userName: res.data.values[0].username,
              password: res.data.values[0].password,
              phone: res.data.values[0].phone,
              role: res.data.values[0].role,
              isActive: res.data.values[0].is_active,
              email: res.data.values[0].email,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidMount() {
    this.onGetData();
    showDataMaster.district();
    showDataMaster.village();
  }

  render() {

    const {
      districVal,
      villageVal,
      districtId,
      villageId,
      name,
      userName,
      password,
      phone,
      role,
      isActive,
      email,
      isOnceSubmit,
    } = this.state

    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid>
          <Heading as='h4' size='xl' mb='20px'>
            Tambah Data User
          </Heading>
        </SimpleGrid>

        <Box>
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='5px'>
            <Card mb='10px'>
              <Box>
                <FormLabel></FormLabel>
                <Select
                  placeholder='Kecamatan'
                  name='districtId'
                  value={districtId}
                  onChange={this.changeHandler}>
                  {districVal?.map((val) => (
                    <option value={val.district_id}>{val.district_name}</option>
                  ))}
                </Select>
                <FormLabel></FormLabel>
                <Select
                  placeholder='Desa'
                  name='villageId'
                  value={villageId}
                  onChange={this.changeHandler}>
                  {villageVal?.map((val) => (
                    <option value={val.village_id}>{val.village_name}</option>
                  ))}
                </Select>
                <FormLabel></FormLabel>
                <Input
                  required
                  value={name}
                  name='name'
                  onChange={this.changeHandler}
                  variant='outline'
                  placeholder='Nama'
                />
                <FormLabel></FormLabel>
                <Input
                  required
                  value={userName}
                  name='userName'
                  onChange={this.changeHandler}
                  variant='outline'
                  placeholder='username'
                />
                <FormLabel></FormLabel>
                <Input
                  required
                  value={email}
                  name='email'
                  onChange={this.changeHandler}
                  variant='outline'
                  placeholder='email@.com'
                  type=''
                />
                <FormLabel></FormLabel>
                <Input
                  required
                  value={phone}
                  name='phone'
                  onChange={this.changeHandler}
                  variant='outline'
                  placeholder='085...'
                  type=''
                />
                <FormLabel></FormLabel>
                <Input
                  required
                  value={password}
                  name='password'
                  onChange={this.changeHandler}
                  variant='outline'
                  placeholder='Password'
                  md='30px'
                />
                <FormLabel></FormLabel>
                <Select placeholder='Plih Hak Akses'
                  name='role'
                  value={role}
                  onChange={this.changeHandler}>
                  <option value='admin'>Admin</option>
                  <option value='viewer'>Viewer</option>
                  <option value='approval'>Approval</option>
                  <option value='ppk'>PPK</option>
                  <option value='pps'>PPS</option>
                </Select>
                <FormLabel></FormLabel>
                <Select placeholder='Aktif' 
                  name='isActive'
                  value={isActive}
                  onChange={this.changeHandler}>
                  <option value='1'>Aktif</option>
                  <option value='0'>Nonaktif</option>
                </Select>
                <FormLabel mb='30px'></FormLabel>
                <Flex>
                  <Button
                    onClick={this.onSubmitOrUpdateData}
                    leftIcon={<TfiSaveAlt />}
                    colorScheme='teal'
                    variant='solid'
                    isLoading={isOnceSubmit}
                    loadingText='Loading...'
                  >
                    Simpan
                  </Button>
									<Spacer/>
                  <Button
                    onClick={this.onGoToBack}
                    leftIcon={<TfiBackLeft />}
                    colorScheme='teal'
                    variant='solid'
                    isLoading={isOnceSubmit}
                    loadingText='Loading...'
                  >
                    Batal
                  </Button>
                </Flex>
              </Box>
              <Box p='2'></Box>
              <Box></Box>
            </Card>
            {/* <Card mb='10px'></Card> */}
          </SimpleGrid>
        </Box>
      </Box>
    );
  }
}

export default CreateOrModifyuserPage;
