import React, { Component } from 'react'
import { Box } from "@chakra-ui/react";
import ReCAPTCHA from 'react-google-recaptcha'

class CaptchaBase extends Component {
  onChange = (val) => {
    localStorage.setItem('captcha', val)
  }

  render() {
    return (
      <Box pb={3}>
        <ReCAPTCHA
          sitekey='6Ld_x5klAAAAAHcFaq4Mt4cjo6GlCKECqU9kgF-N'
          onChange={this.onChange}
        />
      </Box>
    )
  }
}

export default CaptchaBase
