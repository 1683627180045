import React from 'react'

// Chakra imports
import { Flex, useColorModeValue, h1, Image, Text, Spacer } from '@chakra-ui/react'

// Custom components
import { HSeparator } from 'components/separator/Separator'
import KPULogo from 'assets/img/dashboards/kpu.png'

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white')

  return (
    <Flex align='center' direction='column'>
      <Image src={KPULogo} w='50' h='50' my='5px' />
      <h1>KPU KABUPATEN NGAWI </h1>
      <Text color='tomato'>Laporan Keuangan Badan Adhoc</Text>
      {/* <HSeparator mb='20px' /> */}
			<Spacer />
    </Flex>
  );
}

export default SidebarBrand
